/// <reference types="@types/google.maps" />

/**
 * Import SVG icons for zoom in and zoom out functionality.
 */
import MagnifyingGlassPlus from "~/assets/icons/base/MagnifyingGlassPlus.svg";
import MagnifyingGlassMinus from "~/assets/icons/base/MagnifyingGlassMinus.svg";

import { h, render, type AppContext } from "vue";
import cardPopUp from "~/components/base/search-card/new/index.vue";

/**
 * Returns a reactive state for the Google Maps `Map` instance.
 *
 * @returns {Ref<google.maps.Map | undefined>} A stateful reference to the Google Map object, or `undefined` if not yet initialized.
 */
export const useGoogleMap = () =>
  useState<google.maps.Map | undefined>("google_map", undefined);

/**
 * Returns a reactive state for the HTML element where the Google Map will be rendered.
 *
 * @returns {Ref<HTMLElement | undefined>} A stateful reference to the HTML element for the Google Map, or `undefined` if not yet initialized.
 */
export const useGoogleMapHTML = () =>
  useState<HTMLElement | undefined>("google_map_html", undefined);

/**
 * Returns a reactive state for the Google Maps `AdvancedMarkerElement` instance.
 * This is used for advanced marker customization on the map.
 *
 * @returns {Ref<typeof google.maps.marker.AdvancedMarkerElement | undefined>}
 * A stateful reference to the Advanced Marker Element class, or `undefined` if not yet initialized.
 */
export const useAdvancedMarkerElement = (): Ref<
  typeof google.maps.marker.AdvancedMarkerElement | undefined
> =>
  useState<typeof google.maps.marker.AdvancedMarkerElement | undefined>(
    "advanced_marker_element",
    undefined
  );

/**
 * @deprecated
 */
export const initializeGoogleMap = async (args: {
  map_html: HTMLElement;
  latLng: google.maps.LatLng;
  noControls?: boolean;
  isInfo?: boolean;
  noGesture?: boolean;
}): Promise<google.maps.Map | null> => {
  if (!useGoogleMapHTML().value) {
    console.error("google_map_html is not defined");
    return null;
  }

  const { Map, InfoWindow } = (await google.maps.importLibrary(
    "maps"
  )) as google.maps.MapsLibrary;

  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    "marker"
  )) as google.maps.MarkerLibrary;

  const isNoControls = Boolean(args.noControls);
  const isInfo = Boolean(args.isInfo);
  const isNoGesture = Boolean(args.noGesture);

  const map = new Map(args.map_html as HTMLElement, {
    zoom: 12,
    zoomControl: false,
    mapId: "abb391e0c2eddb52",
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    // restriction: {
    //   latLngBounds: {
    //     north: 85,
    //     south: -85,
    //     west: -180,
    //     east: 180,
    //   },
    // },

    // gestureHandling: "greedy",
    gestureHandling: isNoGesture ? "none" : "auto",
    keyboardShortcuts: false,
  });

  //забираем экземпляры карты и маркеров
  useGoogleMap().value = map;
  useAdvancedMarkerElement().value = AdvancedMarkerElement;
  //забираем экземпляры карты и маркеров

  //подписываемся на изменения доступных тулов
  const mapCapabilities = map.getMapCapabilities();
  map.addListener("mapcapabilities_changed", () => {});
  //подписываемся на изменения доступных тулов

  //центируем карту над маркером
  map.setCenter(args.latLng);
  //центируем карту над маркером

  //создаем метку и сдавим по координатам из аргументов
  const markerConstructor = useAdvancedMarkerElement().value;
  const latLng = new google.maps.LatLng(args.latLng);
  if (markerConstructor) {
    const customMarkerLayout = document.createElement("div");
    customMarkerLayout.className = "gm-custom-marker";
    const marker = new markerConstructor({
      map: map,
      position: latLng,
      content: customMarkerLayout,
    });
    marker.addListener("click", ({ domEvent, latLng }) => {
      // infoWindow.open(marker.map, marker);
    });
  }
  //создаем метку и сдавим по координатам из аргументов

  // создаем кастомный экран с инфой об объекте
  if (isInfo) {
    class Popup extends google.maps.OverlayView {
      position: google.maps.LatLng;
      containerDiv: HTMLDivElement;

      constructor(position: google.maps.LatLng, content: HTMLElement) {
        super();
        this.position = position;
        content.classList.add("gm-popup");
        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("gm-popup-anchor");
        bubbleAnchor.appendChild(content);
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("gm-popup-container");
        this.containerDiv.appendChild(bubbleAnchor);
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }
      override onAdd() {
        this.getPanes()!.floatPane.appendChild(this.containerDiv);
      }
      override onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }
      override draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        )!;
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? "block"
            : "none";

        if (display === "block") {
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top = divPosition.y + "px";
        }

        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }
    // добавляем кастомный экран на карту
    const popup = new Popup(
      latLng,
      document.getElementById("gm-info") as HTMLElement
    );
    popup.setMap(map);
    // добавляем кастомный экран на карту
  }
  // создаем кастомный экран с инфой об объекте

  const infoWindow = new InfoWindow();

  //создаем кастомные кнопки зума карты
  if (!isNoControls) {
    const zoomControlDiv = document.createElement("div");
    zoomControlDiv.classList.add("gm-zoom-control");
    const zoomIn = zoomInButt(useGoogleMap().value);
    const zoomOut = zoomOutButt(useGoogleMap().value);
    zoomControlDiv.appendChild(zoomIn as Node);
    zoomControlDiv.appendChild(zoomOut as Node);
    const { isMobile } = useDevice();
    if (isMobile) {
      useGoogleMap().value?.controls[
        google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(zoomControlDiv);
    } else {
      useGoogleMap().value?.controls[
        google.maps.ControlPosition.INLINE_END_BLOCK_START
      ].push(zoomControlDiv);
    }
  }
  // создаем кастомные кнопки зума карты

  return map;
};

// const getCustomPopUp = async () => {
//   if (typeof window === "undefined") {
//     return null;
//   }

//   await google.maps.importLibrary("maps");

//   return class CardLikePopup extends google.maps.OverlayView {
//     position: google.maps.LatLng;
//     containerDiv: HTMLDivElement;

//     constructor(
//       position: google.maps.LatLng,
//       componentOptions: Record<string, any> | null,
//       component: any
//     ) {
//       super();
//       this.position = position;
//       this.containerDiv = document.createElement("div");
//       this.containerDiv.classList.add("gm-popup-custom");
//       CardLikePopup.preventMapHitsAndGesturesFrom(this.containerDiv);

//   const { vueApp } = useNuxtApp();

//   this.mountComponentWithAppContext(
//     component,
//     { estate: componentOptions, size: "suggestions" },
//     this.containerDiv,
//     vueApp._context as AppContext
//   );
// }

//     mountComponentWithAppContext(
//       component: any,
//       componentProps: Record<string, any>,
//       containerDiv: HTMLElement,
//       appContext: AppContext
//     ) {
//       const vnode = h(component, componentProps);

//       vnode.appContext = appContext;

//       render(vnode, containerDiv);

//       this.unmountComponent = () => render(null, containerDiv);
//     }

//     override onAdd() {
//       this.getPanes()!.floatPane.appendChild(this.containerDiv);
//     }

//     override onRemove() {
//       if (this.containerDiv.parentElement) {
//         this.containerDiv.parentElement.removeChild(this.containerDiv);
//       }
//       if (this.unmountComponent) {
//         this.unmountComponent();
//       }
//     }

//     override draw() {
//       const divPosition = this.getProjection().fromLatLngToDivPixel(
//         this.position
//       )!;
//       const display =
//         Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
//           ? "block"
//           : "none";
//       if (display === "block") {
//         this.containerDiv.style.left = divPosition.x + "px";
//         this.containerDiv.style.top = divPosition.y + "px";
//       }
//       if (this.containerDiv.style.display !== display) {
//         this.containerDiv.style.display = display;
//       }
//     }
//   };
// };

/**
 * Factory function to create a custom Google Maps popup class that uses a Vue component.
 * This class is designed for use within a Nuxt 3 application and requires an `appContext`
 * from the current Vue instance.
 *
 * @returns {Promise<typeof google.maps.OverlayView | null>} Returns a custom popup class or null on the server.
 */
const getCustomPopUp = async () => {
  // Ensure the function is executed only on the client.
  if (typeof window === "undefined") {
    return null; // Return null if executed on the server.
  }

  // Load the Google Maps library on the client.
  await google.maps.importLibrary("maps");

  /**
   * A custom Google Maps popup class that renders a Vue component.
   */
  return class CardLikePopup extends google.maps.OverlayView {
    /**
     * The position of the popup on the map.
     * @type {google.maps.LatLng}
     */
    position: google.maps.LatLng;

    /**
     * The container div that holds the popup content.
     * @type {HTMLDivElement}
     */
    containerDiv: HTMLDivElement;

    /**
     * The unmount function for the Vue component.
     * @type {() => void | null}
     */
    unmountComponent: (() => void) | null = null;

    /**
     * Creates a new instance of the CardLikePopup class.
     * @param {google.maps.LatLng} position - The position where the popup should appear.
     * @param {Record<string, any>} componentProps - The props to pass to the Vue component.
     * @param {object} component - The Vue component to render.
     * @param {AppContext} appContext - The context of the current Vue application.
     */
    constructor(
      position: google.maps.LatLng,
      componentProps: Record<string, any>,
      component: object,
      appContext: AppContext
    ) {
      super();
      this.position = position;

      // Create the container div for the popup.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("gm-popup-container");

      // Prevent the popup from interfering with map gestures.
      CardLikePopup.preventMapHitsAndGesturesFrom(this.containerDiv);

      // Mount the Vue component into the container div.
      this.unmountComponent = this.mountComponentWithAppContext(
        component,
        componentProps,
        this.containerDiv,
        appContext
      );
    }

    /**
     * Mounts a Vue component with the provided application context.
     * @param {object} component - The Vue component to render.
     * @param {Record<string, any>} props - The props to pass to the component.
     * @param {HTMLElement} container - The DOM container for the component.
     * @param {AppContext} appContext - The context of the current Vue application.
     * @returns {() => void} A function to unmount the component.
     */
    private mountComponentWithAppContext(
      component: object,
      props: Record<string, any>,
      container: HTMLElement,
      appContext: AppContext
    ): () => void {
      // Create the virtual node (VNode) for the component.
      const vnode = h(component, props);

      // Attach the application context to the virtual node.
      vnode.appContext = appContext;

      // Render the virtual node into the container.
      render(vnode, container);

      // Return a function to unmount the component.
      return () => {
        render(null, container);
      };
    }

    /**
     * Adds the popup to the map's float pane.
     */
    override onAdd() {
      this.getPanes()!.floatPane.appendChild(this.containerDiv);
    }

    /**
     * Removes the popup from the DOM and unmounts the Vue component.
     */
    override onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
      if (this.unmountComponent) {
        this.unmountComponent();
      }
    }

    /**
     * Updates the popup's position on the map.
     * @override
     */
    override draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      )!;
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? "block"
          : "none";

      if (display === "block") {
        this.containerDiv.style.left = divPosition.x + "px";
        this.containerDiv.style.top = divPosition.y + "px";
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  };
};

/**
 * Asynchronously loads the Google Maps `Popup` class for creating custom popups on the map.
 *
 * The returned `Popup` class extends `google.maps.OverlayView` and allows you to create custom popups
 * with specific positions and content. These popups can be styled and positioned dynamically.
 *
 * @returns {Promise<typeof google.maps.OverlayView>} A class that can be used to create custom popups.
 */
const getSimplePopUp = async () => {
  if (typeof window === "undefined") {
    return null;
  }
  // Load the Google Maps library for maps if it hasn't been loaded already.
  await google.maps.importLibrary("maps");

  // Define and return the Popup class.
  return class Popup extends google.maps.OverlayView {
    /**
     * The geographical position where the popup is anchored.
     * @type {google.maps.LatLng}
     */
    position: google.maps.LatLng;

    /**
     * The container for the popup, which holds the content and styling elements.
     * @type {HTMLDivElement}
     */
    containerDiv: HTMLDivElement;

    /**
     * Creates an instance of the Popup class.
     *
     * @param {google.maps.LatLng} position - The position where the popup should appear on the map.
     * @param {HTMLElement} content - The content of the popup. Defaults to an empty div with an ID of "gm-info" if not provided.
     */
    constructor(position: google.maps.LatLng, content: HTMLElement) {
      super(); // Call the parent class constructor.
      this.position = position;

      // If no content is provided, create a default empty div.
      if (!content) {
        content = document.createElement("div");
        content.id = "gm-info";
      }

      // Add the popup styling class to the content element.
      content.classList.add("gm-popup");

      // Create an anchor element for the popup's bubble and append the content.
      const bubbleAnchor = document.createElement("div");
      bubbleAnchor.classList.add("gm-popup-anchor");
      bubbleAnchor.appendChild(content);

      // Create the main container div and append the anchor.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("gm-popup-container");
      this.containerDiv.appendChild(bubbleAnchor);

      // Prevent the popup from interfering with map gestures.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /**
     * Called when the popup is added to the map.
     * Adds the popup's container to the map's float pane.
     *
     * @override
     */
    override onAdd() {
      this.getPanes()!.floatPane.appendChild(this.containerDiv);
    }

    /**
     * Called when the popup is removed from the map.
     * Removes the popup's container from its parent element.
     *
     * @override
     */
    override onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }

    /**
     * Updates the popup's position on the map.
     *
     * Calculates the pixel position of the popup based on the map's projection and adjusts
     * its CSS styles to ensure proper display. The popup is hidden if it is far off-screen.
     *
     * @override
     */
    override draw() {
      // Get the pixel position of the popup relative to the map.
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      )!;

      // Determine whether the popup should be visible or hidden based on its position.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
          ? "block"
          : "none";

      if (display === "block") {
        this.containerDiv.style.left = divPosition.x + "px";
        this.containerDiv.style.top = divPosition.y + "px";
      }

      // Update the popup's display style if it has changed.
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  };
};

/**
 * Represents a static Google Map instance with optional custom markers, controls, and info windows.
 */
export class StaticMap {
  /**
   * The Google Maps instance.
   * @private
   * @type {google.maps.Map}
   */
  private map: google.maps.Map;

  /**
   * The advanced marker element used to display custom markers on the map.
   * @private
   * @type {google.maps.marker.AdvancedMarkerElement | null}
   */
  private marker: google.maps.marker.AdvancedMarkerElement | null = null;

  /**
   * Creates an instance of the StaticMap class.
   *
   * @param {HTMLElement} mapHTML - The HTML element where the map will be rendered.
   * @param {google.maps.LatLng} latLng - The initial geographical position for centering the map.
   * @param {Object} options - Configuration options for the map.
   * @param {boolean} [options.noControls=false] - If `true`, disables zoom controls on the map.
   * @param {boolean} [options.noGesture=false] - If `true`, disables gesture handling (e.g., zooming, panning).
   * @param {boolean} [options.isInfo=false] - If `true`, adds an info window to the map.
   * @param {boolean} [options.customControls=false] - If `true`, adds custom controls to the map.
   */
  constructor(
    private mapHTML: HTMLElement,
    private latLng: google.maps.LatLng,
    private options: {
      noControls?: boolean;
      noGesture?: boolean;
      isInfo?: boolean;
      customControls?: boolean;
    }
  ) {
    // Initialize the Google Maps instance with the provided options.
    this.map = new google.maps.Map(this.mapHTML, {
      zoom: 12,
      zoomControl: !this.options.noControls,
      center: this.latLng,
      mapId: "7488d931c12f24c7",
      gestureHandling: this.options.noGesture ? "none" : "auto",
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
    });

    // Save the map instance globally using `useGoogleMap`.
    useGoogleMap().value = this.map;

    // Add a marker to the map.
    this.addMarker();

    // Optionally add an info window to the map.
    if (this.options.isInfo) {
      this.addInfoWindow();
    }

    // Optionally add custom controls to the map.
    if (this.options.customControls) {
      this.createCustomControls();
    }
  }

  /**
   * Adds a custom marker to the map using `google.maps.marker.AdvancedMarkerElement`.
   * @private
   */
  private addMarker() {
    const { AdvancedMarkerElement } = google.maps.marker;
    const markerContent = document.createElement("div");
    markerContent.className = "gm-custom-marker";

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.latLng,
      content: markerContent,
    });
  }

  /**
   * Adds a custom info window to the map.
   * This method creates a popup using the `getSimplePopUp` function and attaches it to the map.
   *
   * @private
   * @async
   */
  private async addInfoWindow() {
    const createPopUp = await getSimplePopUp();
    if (!createPopUp) return;
    const popup = new createPopUp(
      this.latLng,
      document.getElementById("gm-info") as HTMLElement
    );
    popup.setMap(this.map);
  }

  /**
   * Adds custom controls to the map by calling the `addCustomControls` function.
   * @private
   */
  private createCustomControls() {
    addCustomControls();
  }

  /**
   * Updates the center of the map to the specified latitude and longitude.
   *
   * @param {google.maps.LatLng} latLng - The new geographical position for centering the map.
   */
  public setCenter(latLng: google.maps.LatLng) {
    this.map.setCenter(latLng);
  }
}

/**
 * Represents a dynamic Google Map with multiple markers,
 * optional info windows, and custom controls.
 */

type MarkerData = {
  position: google.maps.LatLng; // The coordinates of the marker.
  title?: string; // The title of the marker.
  description?: string; // A description of the marker.
  imgs?: string[]; // An array of images for the marker.
  [key: string]: any; // Additional properties for the marker.
};

export class DynamicMap {
  /**
   * The Google Maps instance.
   * @private
   * @type {google.maps.Map}
   */
  private map: google.maps.Map;

  /**
   * The list of markers displayed on the map.
   * @private
   * @type {google.maps.marker.AdvancedMarkerElement[]}
   */
  private markers: google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * Creates an instance of the DynamicMap class.
   *
   * @param {HTMLElement} mapHTML - The HTML element where the map will be rendered.
   * @param {google.maps.LatLng[]} points - An array of marker positions.
   * @param {Object} options - Configuration options for the map.
   * @param {boolean} [options.noControls=false] - If `true`, disables zoom controls on the map.
   * @param {boolean} [options.noGesture=false] - If `true`, disables gesture handling (e.g., zooming, panning).
   * @param {boolean} [options.isInfo=false] - If `true`, enables info windows for markers.
   * @param {boolean} [options.customControls=false] - If `true`, adds custom controls to the map.
   */
  constructor(
    private mapHTML: HTMLElement,
    private points: MarkerData[],
    private options: {
      noControls?: boolean;
      noGesture?: boolean;
      isInfo?: boolean;
      customControls?: boolean;
    },
    private appContext: any
  ) {
    console.log(this.points[0].position);
    // Initialize the Google Maps instance with the provided options.
    this.map = new google.maps.Map(this.mapHTML, {
      center: this.points[0].position ?? new google.maps.LatLng(0, 0),
      zoom: 12,
      mapId: "7488d931c12f24c7",
      gestureHandling: this.options.noGesture ? "none" : "auto",
      zoomControl: !this.options.noControls,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
    });

    // Save the map instance globally using `useGoogleMap`.
    useGoogleMap().value = this.map;

    // Add markers to the map.
    this.addMarkers(this.points);

    // Optionally, enable info windows for markers.
    if (this.options.isInfo) {
      this.addInfoWindow();
    }

    // Optionally, add custom controls to the map.
    if (this.options.customControls) {
      this.createCustomControls();
    }

    // Adjust the map to display all markers.
    this.centerMapToMarkers();
  }

  /**
   * Adds markers to the map at the specified positions.
   *
   * @private
   * @param {MarkerData[]} points - The marker positions and data.
   */
  private addMarkers(points: MarkerData[]) {
    const { AdvancedMarkerElement } = google.maps.marker;
    points.forEach((point, index) => {
      const markerContent = document.createElement("div");
      markerContent.className = "gm-custom-marker";
      markerContent.id = `gm-custom-marker-${index}`;

      const marker = new AdvancedMarkerElement({
        map: this.map,
        position: point.position,
        content: markerContent,
      });
      (marker as any).customData = { ...point };
      this.markers.push(marker);
    });
  }

  /**
   * Updates the markers on the map with new data.
   * Clears the existing markers and adds the new ones.
   *
   * @param {MarkerData[]} points - The new marker positions and data.
   */
  public setMarkers(points: MarkerData[]) {
    this.clearMarkers();
    this.addMarkers(points);
    this.centerMapToMarkers();
  }

  /**
   * Removes all existing markers from the map.
   *
   * @private
   */
  private clearMarkers() {
    this.markers.forEach((marker) => {
      marker.map = null;
    });
    this.markers = [];
  }

  /**
   * Adds info windows to the markers.
   * Clicking a marker displays the corresponding info window.
   *
   * @private
   * @async
   */
  private async addInfoWindow() {
    let createPopUp = await getCustomPopUp();

    let activePopup: any = null;

    this.markers.forEach((marker, index) => {
      marker.addListener("click", () => {
        if (marker.position) {
          const position =
            marker.position instanceof google.maps.LatLng
              ? marker.position
              : new google.maps.LatLng(marker.position);

          if (activePopup) {
            if (activePopup?.markerIndex === index) {
              return;
            }
            activePopup.setMap(null);
            activePopup = null;
          }
          // componentOptions, MyPopupComponent
          // const originalInfoElement = document.getElementById("gm-info");
          // if (!originalInfoElement) {
          //   console.error("gm-info element is not found");
          //   return;
          // }
          // const clonedElement = originalInfoElement.cloneNode(
          //   true
          // ) as HTMLElement;
          if (!createPopUp || !this.appContext) return;
          activePopup = new createPopUp(
            position,
            marker.customData,
            cardPopUp,
            this.appContext
          );

          activePopup.setMap(this.map);

          activePopup.markerIndex = index;
        } else {
          console.error("Marker position is not defined");
        }
      });
    });
  }

  /**
   * Adds custom controls to the map by calling the `addCustomControls` function.
   * @private
   */
  private createCustomControls() {
    addCustomControls();
  }

  /**
   * Adjusts the map view to fit all markers within the visible area.
   *
   * @private
   */
  private centerMapToMarkers() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) => {
      if (marker.position) {
        bounds.extend(marker.position);
      }
    });
    this.map.fitBounds(bounds);
  }
}

/**
 * Factory function to create and initialize a Google Map instance (static or dynamic).
 *
 * @async
 * @param {Object} args - The configuration object for creating the map.
 * @param {HTMLElement} args.mapHTML - The HTML element where the map will be rendered.
 * @param {"static" | "dynamic"} args.type - The type of map to create ("static" or "dynamic").
 * @param {google.maps.LatLng} [args.latLng] - The geographical position for the static map. Required if type is "static".
 * @param {google.maps.LatLng[]} [args.points] - An array of geographical positions for the dynamic map. Required if type is "dynamic".
 * @param {Object} [args.options] - Additional configuration options for the map.
 * @param {boolean} [args.options.noControls=false] - If `true`, disables zoom controls on the map.
 * @param {boolean} [args.options.noGesture=false] - If `true`, disables gesture handling (e.g., zooming, panning).
 * @param {boolean} [args.options.isInfo=false] - If `true`, adds clickable info windows to the markers.
 * @param {boolean} [args.options.customControls=false] - If `true`, adds custom controls to the map.
 *
 * @returns {Promise<StaticMap | DynamicMap>} - A promise that resolves to an instance of `StaticMap` or `DynamicMap`.
 *
 * @throws {Error} If `mapHTML` is not provided.
 * @throws {Error} If required arguments for the specified map type are not provided.
 *
 * @example
 * // Create a static map
 * const staticMap = await useGoogleMapFactory({
 *   mapHTML: document.getElementById("map"),
 *   type: "static",
 *   latLng: new google.maps.LatLng(37.7749, -122.4194),
 *   options: { noControls: true },
 * });
 *
 * @example
 * // Create a dynamic map
 * const dynamicMap = await useGoogleMapFactory({
 *   mapHTML: document.getElementById("map"),
 *   type: "dynamic",
 *   points: [
 *     new google.maps.LatLng(37.7749, -122.4194),
 *     new google.maps.LatLng(34.0522, -118.2437),
 *   ],
 *   options: { isInfo: true },
 * });
 */
export const useGoogleMapFactory = async (args: {
  mapHTML: HTMLElement;
  type: "static" | "dynamic";
  latLng?: google.maps.LatLng;
  points?: MarkerData[];
  options?: {
    noControls?: boolean;
    noGesture?: boolean;
    isInfo?: boolean;
    customControls?: boolean;
  };
  appContext?: any;
}) => {
  const { appContext, mapHTML, type, latLng, points, options } = args;

  if (!mapHTML) {
    throw new Error("mapHTML element is required.");
  }

  await google.maps.importLibrary("maps");
  await google.maps.importLibrary("marker");

  if (type === "static" && latLng) {
    return new StaticMap(mapHTML, latLng, options || {});
  } else if (type === "dynamic" && points) {
    return new DynamicMap(mapHTML, points, options || {}, appContext);
  }

  throw new Error("Invalid arguments for map initialization.");
};

const addCustomControls = () => {
  const zoomControlDiv = document.createElement("div");
  zoomControlDiv.classList.add("gm-zoom-control");
  const zoomIn = zoomInButt(useGoogleMap().value);
  const zoomOut = zoomOutButt(useGoogleMap().value);
  zoomControlDiv.appendChild(zoomIn as Node);
  zoomControlDiv.appendChild(zoomOut as Node);
  const { isMobile } = useDevice();
  if (isMobile) {
    useGoogleMap().value?.controls[
      google.maps.ControlPosition.RIGHT_BOTTOM
    ].push(zoomControlDiv);
  } else {
    useGoogleMap().value?.controls[
      google.maps.ControlPosition.INLINE_END_BLOCK_START
    ].push(zoomControlDiv);
  }
  return zoomControlDiv;
};

const zoomInButt = (map: google.maps.Map | undefined) => {
  if (!map) return;
  const zoomInButton = document.createElement("button");
  const svgIcon = document.createElement("img");
  svgIcon.src = MagnifyingGlassPlus;
  svgIcon.alt = "Zoom In";
  svgIcon.width = 24;
  svgIcon.height = 24;
  zoomInButton.appendChild(svgIcon);
  zoomInButton.classList.add("zoom-button");
  zoomInButton.addEventListener("click", () => {
    if (map.setZoom && typeof map.setZoom === "function") {
      map.setZoom(map.getZoom()! + 1);
    }
  });
  return zoomInButton;
};
const zoomOutButt = (map: google.maps.Map | undefined) => {
  if (!map) return;
  const zoomInButton = document.createElement("button");
  const svgIcon = document.createElement("img");
  svgIcon.src = MagnifyingGlassMinus;
  svgIcon.alt = "Zoom In";
  svgIcon.width = 24;
  svgIcon.height = 24;
  zoomInButton.appendChild(svgIcon);
  zoomInButton.classList.add("zoom-button");
  zoomInButton.addEventListener("click", () => {
    if (map.setZoom && typeof map.setZoom === "function") {
      map.setZoom(map.getZoom()! - 1);
    }
  });
  return zoomInButton;
};
