import type { Currency } from "~/types/currencies";
import { Api } from "~/services/swagger/Api";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import eventBus from "~/utilities/composables/eventBus";

const apiClient = new Api();

export const CURRENCIES: { [key: string]: Currency } = {
  USD: { code: "USD", symbol: "$", rate: 1, usdRate: 1 } as Currency,
} as const;


export const getCurrentCurrency = (key: string | null | undefined) => {
  if (!key) return CURRENCIES[BASE_CURRENCY.value.code];
  return CURRENCIES[key] || CURRENCIES[BASE_CURRENCY.value.code];
};

export const setBaseCurrency = (key: string) => {
  if (CURRENCIES[key]) {
    baseCurrency.value = CURRENCIES[key];
    console.log('baseCurrency.value: ', baseCurrency.value);
  } else {
    console.warn(`Currency with key "${key}" not found. Falling back to USD.`);
    baseCurrency.value = CURRENCIES.USD;
  }
  writePersistentLocalStorage("user_currency", baseCurrency.value);
  eventBus.emit("change-currency");
};

const baseCurrency = ref<Currency>(CURRENCIES.USD);
export const BASE_CURRENCY = computed(() => baseCurrency.value);
export const HUGE_CURRENCY = ["IDR"];

export const USD_CURRENCY = CURRENCIES["USD"];

export const getAllCurrency = async () => {
  try {
    const response = await apiClient.api.currencyRatesControllerList();
    if (!response.ok) return;
    response.data.forEach((item) => {
      CURRENCIES[item.currency] = {
        code: item.currency,
        symbol: item.symbol,
        rate: parseFloat(item.rate.toFixed(2)),
        usdRate: parseFloat(item.inverseRate.toFixed(6)),
      };
    });

    const userCurrency: Currency | null | undefined =
      getLocalStorage("user_currency");
    if (userCurrency) {
      setBaseCurrency(userCurrency.code);
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

getAllCurrency();
