import { default as _91id_934TX6OPXYFyMeta } from "/vercel/path0/main-site/pages/admin/[slug]/[id].vue?macro=true";
import { default as indexUj54fUmrpQMeta } from "/vercel/path0/main-site/pages/admin/reviews/index.vue?macro=true";
import { default as indexsekr5ZUc8dMeta } from "/vercel/path0/main-site/pages/admin/table/index.vue?macro=true";
import { default as indexmc0WJWZlU3Meta } from "/vercel/path0/main-site/pages/comparison/index.vue?macro=true";
import { default as indexyNIvqogcGZMeta } from "/vercel/path0/main-site/pages/cookies-policy/index.vue?macro=true";
import { default as indexoU2tVagPzeMeta } from "/vercel/path0/main-site/pages/directory/index.vue?macro=true";
import { default as indexDLt1D0blPvMeta } from "/vercel/path0/main-site/pages/directory/map/index.vue?macro=true";
import { default as indexK4XvG5A0TvMeta } from "/vercel/path0/main-site/pages/faq/index.vue?macro=true";
import { default as index0YrYbybkpgMeta } from "/vercel/path0/main-site/pages/index.vue?macro=true";
import { default as indexpgQMfOTzCOMeta } from "/vercel/path0/main-site/pages/privacy-policy/index.vue?macro=true";
import { default as indexxSG9r80s1RMeta } from "/vercel/path0/main-site/pages/profile/index.vue?macro=true";
import { default as index0tGeh1nijuMeta } from "/vercel/path0/main-site/pages/property/[id]/index.vue?macro=true";
import { default as indexmHCekvva8FMeta } from "/vercel/path0/main-site/pages/terms/index.vue?macro=true";
import { default as emailQRrn3hsOVEMeta } from "/vercel/path0/main-site/pages/third-party-auth/email.vue?macro=true";
import { default as googleJlNM572PCjMeta } from "/vercel/path0/main-site/pages/third-party-auth/google.vue?macro=true";
import { default as component_45stubhw4t3FiROCMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubhw4t3FiROC } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-slug-id___en",
    path: "/admin/:slug()/:id()",
    component: () => import("/vercel/path0/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___de",
    path: "/de/admin/:slug()/:id()",
    component: () => import("/vercel/path0/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-slug-id___ru",
    path: "/ru/admin/:slug()/:id()",
    component: () => import("/vercel/path0/main-site/pages/admin/[slug]/[id].vue")
  },
  {
    name: "admin-reviews___en",
    path: "/admin/reviews",
    meta: indexUj54fUmrpQMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___de",
    path: "/de/admin/reviews",
    meta: indexUj54fUmrpQMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-reviews___ru",
    path: "/ru/admin/reviews",
    meta: indexUj54fUmrpQMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/reviews/index.vue")
  },
  {
    name: "admin-table___en",
    path: "/admin/table",
    meta: indexsekr5ZUc8dMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___de",
    path: "/de/admin/table",
    meta: indexsekr5ZUc8dMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/table/index.vue")
  },
  {
    name: "admin-table___ru",
    path: "/ru/admin/table",
    meta: indexsekr5ZUc8dMeta || {},
    component: () => import("/vercel/path0/main-site/pages/admin/table/index.vue")
  },
  {
    name: "comparison___en",
    path: "/comparison",
    meta: indexmc0WJWZlU3Meta || {},
    component: () => import("/vercel/path0/main-site/pages/comparison/index.vue")
  },
  {
    name: "comparison___de",
    path: "/de/comparison",
    meta: indexmc0WJWZlU3Meta || {},
    component: () => import("/vercel/path0/main-site/pages/comparison/index.vue")
  },
  {
    name: "comparison___ru",
    path: "/ru/comparison",
    meta: indexmc0WJWZlU3Meta || {},
    component: () => import("/vercel/path0/main-site/pages/comparison/index.vue")
  },
  {
    name: "cookies-policy___en",
    path: "/cookies-policy",
    component: () => import("/vercel/path0/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___de",
    path: "/de/cookies-policy",
    component: () => import("/vercel/path0/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___ru",
    path: "/ru/cookies-policy",
    component: () => import("/vercel/path0/main-site/pages/cookies-policy/index.vue")
  },
  {
    name: "directory___en",
    path: "/directory",
    meta: indexoU2tVagPzeMeta || {},
    component: () => import("/vercel/path0/main-site/pages/directory/index.vue")
  },
  {
    name: "directory___de",
    path: "/de/directory",
    meta: indexoU2tVagPzeMeta || {},
    component: () => import("/vercel/path0/main-site/pages/directory/index.vue")
  },
  {
    name: "directory___ru",
    path: "/ru/directory",
    meta: indexoU2tVagPzeMeta || {},
    component: () => import("/vercel/path0/main-site/pages/directory/index.vue")
  },
  {
    name: "directory-map___en",
    path: "/directory/map",
    component: () => import("/vercel/path0/main-site/pages/directory/map/index.vue")
  },
  {
    name: "directory-map___de",
    path: "/de/directory/map",
    component: () => import("/vercel/path0/main-site/pages/directory/map/index.vue")
  },
  {
    name: "directory-map___ru",
    path: "/ru/directory/map",
    component: () => import("/vercel/path0/main-site/pages/directory/map/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: indexK4XvG5A0TvMeta || {},
    component: () => import("/vercel/path0/main-site/pages/faq/index.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: indexK4XvG5A0TvMeta || {},
    component: () => import("/vercel/path0/main-site/pages/faq/index.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    meta: indexK4XvG5A0TvMeta || {},
    component: () => import("/vercel/path0/main-site/pages/faq/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: index0YrYbybkpgMeta || {},
    component: () => import("/vercel/path0/main-site/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: index0YrYbybkpgMeta || {},
    component: () => import("/vercel/path0/main-site/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: index0YrYbybkpgMeta || {},
    component: () => import("/vercel/path0/main-site/pages/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/vercel/path0/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/ru/privacy-policy",
    component: () => import("/vercel/path0/main-site/pages/privacy-policy/index.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: indexxSG9r80s1RMeta || {},
    component: () => import("/vercel/path0/main-site/pages/profile/index.vue")
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: indexxSG9r80s1RMeta || {},
    component: () => import("/vercel/path0/main-site/pages/profile/index.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile",
    meta: indexxSG9r80s1RMeta || {},
    component: () => import("/vercel/path0/main-site/pages/profile/index.vue")
  },
  {
    name: "property-id___en",
    path: "/property/:id()",
    meta: index0tGeh1nijuMeta || {},
    component: () => import("/vercel/path0/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "property-id___de",
    path: "/de/property/:id()",
    meta: index0tGeh1nijuMeta || {},
    component: () => import("/vercel/path0/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "property-id___ru",
    path: "/ru/property/:id()",
    meta: index0tGeh1nijuMeta || {},
    component: () => import("/vercel/path0/main-site/pages/property/[id]/index.vue")
  },
  {
    name: "terms___en",
    path: "/terms",
    component: () => import("/vercel/path0/main-site/pages/terms/index.vue")
  },
  {
    name: "terms___de",
    path: "/de/terms",
    component: () => import("/vercel/path0/main-site/pages/terms/index.vue")
  },
  {
    name: "terms___ru",
    path: "/ru/terms",
    component: () => import("/vercel/path0/main-site/pages/terms/index.vue")
  },
  {
    name: "third-party-auth-email___en",
    path: "/third-party-auth/email",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___de",
    path: "/de/third-party-auth/email",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-email___ru",
    path: "/ru/third-party-auth/email",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/email.vue")
  },
  {
    name: "third-party-auth-google___en",
    path: "/third-party-auth/google",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___de",
    path: "/de/third-party-auth/google",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: "third-party-auth-google___ru",
    path: "/ru/third-party-auth/google",
    component: () => import("/vercel/path0/main-site/pages/third-party-auth/google.vue")
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/search",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/:lang/search/:pathMatch(.*)",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubhw4t3FiROC
  },
  {
    name: component_45stubhw4t3FiROCMeta?.name,
    path: "/ru-RU-sitemap.xml",
    component: component_45stubhw4t3FiROC
  }
]