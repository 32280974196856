<template>
  <nav ref="pageTabsRef" class="base-page-tabs">
    <div class="tabs-wrapper">
      <button
        v-for="(item, index) in props.tabs"
        :disabled="disable === item"
        class="tabs-wrapper-tab"
        :class="[
          'tabs-wrapper-tab',
          'font--b5-2',
          'text-black-monochrome',
          { isActive: index === currentIndex },
        ]"
        @click="toggleTabs(item)"
      >
        {{ item }}
      </button>
    </div>
  </nav>
</template>

<script setup lang="ts">
interface Props {
  tabs: string[];
  currentTab: number;
  disable?: string;
}
interface Emits {
  (event: "change-tab", value: string | undefined): void;
}

const props = withDefaults(defineProps<Props>(), {
  tabs: () => ["About Property", "Finance", "Documents"],
  currentTab: 0,
});
const emit = defineEmits<Emits>();
const { isMobile } = useDevice();

const currentIndex = computed(() => props.currentTab);
const pageTabsRef = ref<HTMLElement | null>(null);
const pageTabsW = computed(() => pageTabsRef.value?.clientWidth ?? 0);

const getItemW = (item: string) => {
  return 14 * item.length + 48;
};

const scrollTabs = (item: string) => {
  if (!isMobile) return;
  const targetElement = document.querySelector(".base-page-tabs");
  const left =
    props.tabs?.indexOf(item) === 0 ? 0 : pageTabsW.value + getItemW(item);
  targetElement?.scrollTo({
    left: left,
    behavior: "smooth",
  });
};

const toggleTabs = (item: string) => {
  if (props.disable === item) return;
  scrollTabs(item);
  emit("change-tab", item);
};

watch(currentIndex, () => {
  scrollTabs(props.tabs[currentIndex.value]);
});
</script>

<style scoped lang="scss">
.base-page-tabs {
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px 12px 24px;
  z-index: 10;
  background-color: var(--white-monochrome);
  .tabs-wrapper {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 48px;

    &-tab {
      position: relative;
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      background: transparent;
      cursor: pointer;
      z-index: 3;
      font-weight: 400;
      box-shadow: none;
      transition:
        color 0.2s ease,
        font-weight 0.2s ease;
      &::before {
        content: "";
        position: absolute;
        top: -50%;
        left: -24px;
        width: calc(100% + 48px);
        height: 38px;
        background-color: transparent;
        border-radius: 30px;
        z-index: -1;
        transition:
          background-color 0.2s ease,
          box-shadow 0.2s ease;
      }
    }
  }
  .isActive {
    font-weight: 500;
    &::before {
      background-color: var(--white-contrast);
      box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
