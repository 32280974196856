<template>
  <section class="stock-chart">
    <div class="stock-chart__head">
      <p class="font--b1-2">Tokens available</p>
      <span class="controls">
        <button
          v-for="item in filters"
          class="controls__button font--b5"
          :class="{ 'controls__button--active': item.emit === currentFilter }"
          @click="handleFilterChange(item)"
        >
          {{ item.label }}
        </button>
      </span>
    </div>
    <div class="stock-chart__wrapper">
      <Line :data="data" :options="options" />
      <base-loader
        v-if="props.loading"
        no-background
        class="stock-chart__wrapper--loader"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import type { ChartsDTO, ChartsResponseDTO } from "~/services/swagger/Api";
import { abbreviateNumber } from "~/utilities/helpers/format-data/number";

interface IProps {
  chartData: ChartsResponseDTO;
  totalSupply: string | null | undefined;
  loading?: boolean;
}

const props = defineProps<IProps>();

const emit = defineEmits<{
  (event: "change-period", value: Filter["emit"]): void;
}>();

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Filter {
  label: string;
  emit: "week" | "month" | "year" | "all_time";
}

const filters: Filter[] = [
  { label: "Week", emit: "week" },
  { label: "Month", emit: "month" },
  { label: "Year", emit: "year" },
  // { label: "All time", emit: "all_time" },
];

const currentFilter = ref<Filter["emit"]>("week");

const handleFilterChange = (filter: Filter) => {
  currentFilter.value = filter.emit;
  emit("change-period", filter.emit);
};

const data = computed(() => {
  const labels: string[] = [];
  const dataset1Data =
    props.chartData.charts?.map((item) => parseFloat(item.availableSupply)) ??
    [];
  const dataset2Data =
    props.chartData.charts?.map((item) =>
      parseFloat(props.totalSupply ?? "0")
    ) ?? [];

  // Преобразуем createdAt в метки
  const now = new Date();

  if (currentFilter.value === "week") {
    // Отображаем последние 7 дней
    for (let i = 6; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(now.getDate() - i);
      labels.push(`${date.getDate()}/${date.getMonth() + 1}`);
    }
  } else if (currentFilter.value === "month") {
    // Отображаем последние 4 недели
    for (let i = 3; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(now.getDate() - i * 7); // смещаем на 7 дней * i
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - date.getDay()); // начало недели
      labels.push(
        `Week of ${startOfWeek.getDate()}/${startOfWeek.getMonth() + 1}`
      );
    }
  } else if (currentFilter.value === "year") {
    // Отображаем последние 12 месяцев
    for (let i = 11; i >= 0; i--) {
      const date = new Date(now);
      date.setMonth(now.getMonth() - i); // смещаем на i месяцев назад
      labels.push(`${date.getMonth() + 1}/${date.getFullYear()}`);
    }
  }
  console.log(labels);
  return {
    labels,
    datasets: [
      {
        data: dataset1Data,
        borderColor: "#5A2FCF",
        backgroundColor: "transparent",
        borderWidth: 3,
        pointBackgroundColor: "#5A2FCF",
        pointBorderColor: "#fff",
        pointBorderWidth: 1,
        pointRadius: (ctx) =>
          ctx.dataIndex === dataset1Data.length - 1 ? 6 : 0,
      },
      {
        data: dataset2Data,
        borderColor: "transparent",
        backgroundColor: "transparent",
      },
    ],
  };
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 1,
      },
      border: {
        color: "#9EA7B966",
        lineWidth: 1,
        dash: [7, 7],
      },
      ticks: {
        font: {
          family: "'DM Sans', sans-serif",
          size: 10,
          weight: "400",
        },
        color: "#12121299",
      },
    },

    y: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        stepSize: 50,
        callback: (value) => `${abbreviateNumber(value)} tokens`,
        font: {
          family: "'DM Sans', sans-serif",
          size: 10,
          weight: "400",
        },
        color: "#12121299",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.stock-chart {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 620px;

  p {
    cursor: pointer;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin-right: 10px;
      &__button {
        @include drop-button-styles;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--gray-monochrome-40);
        transition:
          background-color 0.3s ease,
          scale 0.3s ease,
          font-weight 0.3s ease;
        will-change: background-color, scale, font-weight;
        &--active {
          font-weight: 600;
          border-radius: 8px;
          background: var(--white-monochrome);
          box-shadow: var(--shadow-light)
        }
        &:hover {
          background-color: var(--gray-monochrome-20);
        }
        &:active {
          scale: 0.9;
        }
      }
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 620px;
    height: 311px;

    &--loader {
      position: absolute;
      top: 0;
    }
  }
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .stock-chart {
    max-width: 100dvw;
    &__head {
      flex-direction: column;
      gap: 10px;
      .controls {
        margin: 0;
      }
    }
  }
}
</style>
