<template>
  <section class="sign-in">
    <div class="sign-in__header">
      <h5 class="text-black-monochrome">
        {{ $t("favorite.remove") }}
      </h5>
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
    </div>
    <div class="sign-in__controls">
      <base-button
        class="sign-butt"
        :text="$t('favorite.yes')"
        size="xs"
        variant="prime"
        type="submit"
        @click="handleSignClick"
      />
      <span class="flexbox">
        <base-button
          class="sign-butt"
          :text="$t('favorite.no')"
          size="xs"
          variant="dark_ghost"
          type="submit"
          @click="close"
        />
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
const $q = useQuasar();
import { userStore } from "~/store/user";
const useUserStore = userStore();
const emit = defineEmits(["hide_popup"]);

interface IProps {
  data: { id: string; func: (id: string) => Promise<boolean> };
}

const props = defineProps<IProps>();

const handleSignClick = async () => {
  console.log('props.data: ', props.data);
  // await useUserStore.addToFavorite(props.data);
  await props.data.func(props.data.id);
  await useUserStore.fetchUserData();
  emit("hide_popup", "ca");
};

const close = async () => {
  emit("hide_popup", "ca");
};
</script>

<style scoped lang="scss">
.sign-in {
  position: relative;
  display: inline-flex;
  min-width: 400px;
  max-width: 400px;
  height: fit-content;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-40);
  background: var(--white-contrast);
  max-height: 90dvh;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-40);
    border-radius: 6px;
    border: 2px solid transparent;
  }

  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-color: var(--violet-secondary-light)
        var(--violet-secondary-light);
    }
  }
  @media (max-width: 65rem) {
    min-width: unset;
    width: 90vw;
    padding: 24px;
  }
  &__header {
    .title-underlined {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        bottom: -1px;
        left: 0;
        background-color: var(--violet-main);
      }
    }
    .close-butt {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 28px;
      height: 28px;
      margin: 0;
      outline: none;
      border: none;
      border-radius: 50%;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      background: var(--Monochrome-White, #e9ebf8);
      cursor: pointer;
    }
  }
  &__controls {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 12px;
    justify-items: center;
    .sign-butt.base-button {
      width: 100%;
    }
    .flexbox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      .base-button {
        width: 100%;
      }
    }
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
}
</style>
