<template>
  <header v-if="failSafeInterval" class="failsafe-header">
    <h1>Next save in {{ failDsafeTimerDisplay }}s</h1>
  </header>
  <div v-if="!$q.loading.isActive && currentItem" class="float-butts">
    <q-fab
      v-model="floatButt"
      label="Actions"
      vertical-actions-align="left"
      color="purple"
      icon="keyboard_arrow_up"
      direction="up"
    >
      <q-fab-action
        v-if="currentItem.sanitizeName"
        color="primary"
        label="Preview"
        @click="openPreview(currentItem.sanitizeName)"
      />
      <q-fab-action
        color="secondary"
        :label="adminStore.formMode === 'edit' ? 'Update' : 'Add'"
        @click="handleSubmit"
      />
      <q-fab-action
        color="orange"
        label="Start failsafe"
        @click="handleFailSave"
      />
      <q-fab-action
        color="accent"
        label="Get saved item"
        @click="handleGetFailSave"
      />
    </q-fab>
  </div>
  <div class="page-content">
    <div v-if="!$q.loading.isActive && currentItem" class="unit-container">
      <section class="unit__left">
        <div v-if="!$device.isMobile" class="wall-wrapper">
          <base-files-uploader />
        </div>
        <base-page-tabs
          :tabs="props.tabs"
          :current-tab="currentTab"
          :disable="adminStore.formMode === 'edit' ? 'Provider' : ''"
          @change-tab="onTabClick"
        />

        <swiper
          :slides-per-view="1"
          :space-between="30"
          :allow-touch-move="false"
          @swiper="onSwiper"
          @slide-change="onSlideChange"
        >
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-dynamic">
                <div class="unit-bubbles">
                  <slot name="bubbles" :currentItem="currentItem" />
                </div>
              </section>
              <div class="current__tab-text">
                <editor
                  v-model="currentItem.description"
                  api-key="v5n069t1i4u2b85xtio3jrmx4gktki4qys7iaksiafi1fzzq"
                  tag-name="div"
                />
              </div>
              <div class="current__tab-map">
                <q-input
                  v-model="currentItem.location"
                  standout="bg-black text-white"
                  label="Location"
                />
                <q-select
                  v-model="currentItem.countryId"
                  standout
                  :options="countries"
                  label="Country"
                  option-value="code"
                  option-label="name"
                  emit-value
                  map-options
                />
                <q-select
                  v-if="isInTrading"
                  v-model="tradingQueue"
                  :options="tradingQueueOptions"
                  standout
                  label="Place in trading queue"
                  emit-value
                />
                <q-select
                  v-model="currentItem.stage"
                  standout
                  :options="['complete', 'in_progress']"
                  label="Stage"
                  emit-value
                />
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <slot name="finance" :currentItem="currentItem" />
            </div>
          </swiper-slide>

          <swiper-slide v-if="props.isTimeline">
            <div class="current__tab">
              <base-unit-timeline
                v-if="currentItem.timeline"
                is-edit
                :timeline-arr="currentItem.timeline"
                @change-timeline="handleTimelineChanged"
              />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <div class="wrapper">
                  <q-input
                    v-model="currentItem.token!.name"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Name"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.address"
                    standout="bg-black text-white"
                    type="text"
                    label="Address"
                    style="width: 100%"
                  />
                  <q-select
                    v-model="currentItem.token!.chainId"
                    standout
                    :options="blockchains"
                    label="Token Chain"
                    option-label="name"
                    option-value="id"
                    emit-value
                    map-options
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.symbol"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Symbol"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.decimals"
                    standout="bg-black text-white"
                    type="number"
                    label="Token Decimals"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.totalSupply"
                    standout="bg-black text-white"
                    type="number"
                    label="Total Supply"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.availableSupply"
                    standout="bg-black text-white"
                    type="number"
                    label="AvailableSupply Supply"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.icon"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Icon URL"
                    style="width: 100%"
                  />
                  <q-input
                    v-model="currentItem.token!.description"
                    standout="bg-black text-white"
                    type="text"
                    label="Token Description"
                    style="width: 100%"
                  />

                  <q-select
                    v-model="currentItem.token!.jurisdiction"
                    standout
                    :options="countries"
                    label="Token Jurisdiction"
                    option-value="code"
                    option-label="name"
                    emit-value
                    map-options
                    style="width: 100%"
                  />
                </div>
              </section>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <q-select
                  filled
                  v-model="currentItem.token.issuer"
                  label="Issuers"
                  :options="allIssuers"
                  option-label="name"
                  option-value="id"
                  use-chips
                  @filter="getIssuers"
                >
                </q-select>
              </section>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="current__tab">
              <section class="current__tab-finance">
                <div>
                  <q-select
                    filled
                    v-model="pickedPartner"
                    label="Partners"
                    :options="allPartners"
                    emit-value
                    option-label="name"
                    multiple
                    use-chips
                    @filter="getPartners"
                    @add="addPartner"
                    @remove="removePartner"
                  >
                  </q-select>
                </div>
              </section>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <div class="current__tab-map">
                <q-select
                  v-model="currentItem.ownershipStatus"
                  standout
                  :options="holdOptions"
                  label="Ownership rights"
                  emit-value
                  clearable
                />
                <q-select
                  v-model="currentItem.ownershipText"
                  standout
                  :options="templateOptions"
                  label="Legal Template"
                  emit-value
                  clearable
                />
              </div>
              <div class="current__tab-text">
                <p>
                  {{
                    currentItem.ownershipText
                      ? LegalTemplates[
                          currentItem.ownershipText as keyof typeof LegalTemplates
                        ]
                      : ""
                  }}
                </p>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="current__tab">
              <div class="current__tab-map">
                <q-select
                  v-model="currentItem.provider"
                  standout
                  :options="providersOptions"
                  label="Pick provider"
                  clearable
                  option-label="name"
                  option-value="id"
                  emit-value
                  map-options
                />
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <section class="unit__right">
        <slot name="searchCard" :currentItem="currentItem" />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { useAdminStore, type CompanyResponseDTO } from "~/store/admin";
import type {
  CreateEstateTimelineDTO,
  UnitProviderDTO,
  PartnerResponseDTO,
} from "~/services/swagger/Api";
import editor from "@tinymce/tinymce-vue";
import { LegalTemplates } from "~/types/admin";
import { askForFailSafe, getFailSafeItem } from "./common";
import { cloneDeep } from "lodash";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";

definePageMeta({
  layout: "admin-edit",
  middleware: "auth-middleware",
});

interface IProps {
  tabs: string[];
  isTimeline?: boolean;
}

const props = defineProps<IProps>();

const route = useRoute();
const templateOptions = Object.keys(LegalTemplates).map((key) => key);

const adminStore = useAdminStore();
const currentItem = computed(() => adminStore.currentItem);
const unit = adminStore.getUnitClass();
const holders = adminStore.getHoldersClass();
const allPartners = ref<PartnerResponseDTO[] | undefined>([]);
const allIssuers = ref<CompanyResponseDTO[] | undefined>([]);
const pickedPartner = ref<PartnerResponseDTO[] | undefined>(undefined);

const holdOptions = ["Freehold", "Leasehold"];

const countries = computed(() => adminStore.countries);
const blockchains = computed(() => adminStore.blockchains);
const $q = useQuasar();
const isInTrading = computed(() =>
  currentItem.value?.tags?.includes("trending")
);
const tradingQueueOptions = computed(() => {
  let length = adminStore.items?.filter(
    (item) => Array.isArray(item.tags) && item.tags.includes("trending")
  ).length;
  if (!length) return [];
  if (adminStore.formMode === "add") {
    length++;
  }
  return Array.from({ length }, (_, i) => i + 1);
});
const tradingQueue = ref<number>(0);
const floatButt = ref(false);
const providersOptions = ref<UnitProviderDTO[] | null>(null);

const currentTab = ref(0);

const handleTimelineChanged = (point: {
  icon: string;
  activeColor: string;
  isActive: boolean;
  text: string;
  date: string;
  stage: string;
}) => {
  if (!point) return;
  const timeLinePoint: CreateEstateTimelineDTO = {
    stage: point.stage as CreateEstateTimelineDTO["stage"],
    date: point.date || "",
  };

  if (timeLinePoint.date) {
    adminStore.updateTimeline(currentItem.value.id, timeLinePoint);
  } else {
    adminStore.deleteTimeline(currentItem.value.id, timeLinePoint);
  }
};

const handleSubmit = () => {
  checkBlankProps();
  if (adminStore.formMode === "add") {
    onPropertySave(cloneDeep(currentItem.value), tradingQueue.value);
  } else if (adminStore.formMode === "edit") {
    onPropertyUpdate(cloneDeep(currentItem.value), tradingQueue.value);
  }
};

const onPropertySave = async (
  item: UpdatedUnitResponseDTO,
  queue_pos: number
) => {
  try {
    item.providerId = item.provider;
    delete item.provider;
    const response = await unit.addProperty(item);
    const queueObj = {
      id: response?.data?.id,
      queue: queue_pos,
    };
    await onUpdateQueue(queueObj);
    alert("Estate successfully added!");
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};

const onPropertyUpdate = async (
  item: UpdatedUnitResponseDTO,
  queue_pos: number
) => {
  try {
    item.providerId = item.provider;
    delete item.provider;
    const response = await unit.updateProperty(item, pickedPartner.value);
    const queueObj = {
      id: response?.data?.id,
      queue: queue_pos,
    };
    await onUpdateQueue(queueObj);
    alert("Estate successfully updated!");
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};

const onUpdateQueue = async (item: { id: string; queue: number }) => {
  if (!item.id) return;
  const IdsArray = adminStore.items
    .filter((item) => item.tags?.includes("trending"))
    .map((item) => item.id);
  function moveElementToPosition<T>(
    arr: T[],
    element: T,
    toIndex: number
  ): T[] {
    const to = toIndex - 1;
    if (to < 0 || to >= arr.length) {
      console.error("Index is out of array");
      // throw new Error("Index is out of array");
    }
    const from = arr.indexOf(element);

    if (from === -1) {
      // throw new Error("Element not found in array");
      console.log("not in array");
      return [];
    }
    arr.splice(from, 1);
    arr.splice(to, 0, element);

    return arr;
  }
  const result = moveElementToPosition(IdsArray, item.id, item.queue);
  await adminStore.updateQueue(result);
};

const swiperInst = ref<typeof Swiper | null>(null);

const addPartner = (details: { index: number; value: PartnerResponseDTO }) => {
  adminStore.currentItem.partners?.push({
    ...details.value,
    id: allPartners.value?.find((item) => item.name === details.value.name)?.id,
  });
};
const removePartner = (details: {
  index: number;
  value: PartnerResponseDTO;
}) => {
  adminStore.$state.currentItem.partners?.splice(details.index, 1);
};

const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};

const onTabClick = (tabName: string | undefined) => {
  if (!tabName) return;
  const tabIndex = props.tabs.indexOf(tabName);
  if (swiperInst.value && tabIndex !== -1) {
    currentTab.value = tabIndex;
    swiperInst.value.slideTo(tabIndex);
  }
};
const onSlideChange = (swiper: { activeIndex: number }) => {
  if (typeof swiper?.activeIndex !== "number") return 0;
  currentTab.value = swiper.activeIndex;
};

const openPreview = (id: string) => {
  if (!id) return;
  window.open(`${window.location.origin}/property/${id}`);
};

const checkBlankProps = () => {
  currentItem.value.partners?.forEach((item) => {
    if (!item.url || !item.url.trim()) {
      delete item.url;
    }
  });
};

const failSafeInterval = ref<NodeJS.Timeout>();
const failDsafeTimerDisplay = ref(10);
const handleFailSave = () => {
  askForFailSafe(currentItem.value, failDsafeTimerDisplay, failSafeInterval);
};

const handleGetFailSave = async () => {
  const res = getFailSafeItem(failSafeInterval);
  if (res) {
    adminStore.$patch({
      currentItem: { ...res } as unknown as UpdatedUnitResponseDTO,
    });
  }
};

const checkAndOpenPage = async () => {
  if (route.params.slug === "edit") {
    await unit.onOpenEditPage(route.params.id as string);
  } else {
    adminStore.createNewProperty(route.params.id as string);
  }
  adminStore.getCountries();
  adminStore.getBlockchains();
};

const getPartners = async (val: any, update: any, abort: any) => {
  if (allPartners.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllPartners();
  if (!res) return;
  update(() => {
    allPartners.value = res;
  });
};
const getIssuers = async (val: any, update: any, abort: any) => {
  if (allIssuers.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllCompanies();
  if (!res) return;
  update(() => {
    allIssuers.value = res;
  });
};

onMounted(async () => {
  $q.loading.show();
  await checkAndOpenPage();
  holders.fetchAllPartners(true)
  providersOptions.value = await adminStore.getProviders();
  pickedPartner.value = currentItem.value.partners;
  $q.loading.hide();
});
</script>

<style scoped lang="scss">
@import url(~/assets/styles/admin-unit-page/index.scss);
</style>
