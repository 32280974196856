const getRoute = (args: {
  endpont: string;
  id?: string | number | undefined;
  rest?: string | number | undefined;
}) => {
  const apiMap: { [key: string]: string } = {
    mail_list: "/api/mailing-list/email",
    post_units: "/api/units/filter",
    get_filter_stats_estate: "/api/units/stats",
    // get_filter_stats_land: "/api/lands/stats",
    get_countries: `/api/countries`,
    get_blockchains: `/api/chains`,
    post_check_username:'/api/auth/native/sign-up/check',
    get_companies:'/api/companies',
    get_unit:`/api/units/${args.id}`
  };
  return apiMap[args.endpont];
};

export default getRoute;
