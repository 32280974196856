<template>
  <base-logo-loader :is-loading="isInitialLoading" />
  <section class="page-content animate__animated animate__fadeIn">
    <div class="search-mobile">
      <base-search @typing="debouncedHandleMobilSearch" :search="search" />
      <div class="search-mobile__btns">
        <keep-alive>
          <search-mobile-filter>
            <template #button="{ onClick }">
              <base-button
                :text="$t('search_filters.fr_sort')"
                size="xss"
                icon-name="base/Sliders"
                icon-size="16px"
                variant="light_bordered"
                class="btn-sort"
                @click="onClick"
              />
            </template>
            <div class="mobile-sort">
              <div class="mobile-sort__title">
                {{ $t("search_filters.fr_sort") }}
              </div>
              <div
                v-for="(item, index) in sortingFiltersMobile"
                class="mobile-sort__item"
                :class="{
                  selected:
                    selectedFilterMobile?.id === item.id &&
                    selectedFilterMobile?.label === item.label,
                }"
                @click="sortEstatesMobile(item)"
              >
                {{ item.label }}
              </div>
            </div>
          </search-mobile-filter>
        </keep-alive>
        <keep-alive>
          <search-mobile-filter>
            <base-navigator
              :handleStageCheckboxClick="handleStageCheckboxClick"
              :stageCheckboxes="stageCheckboxes"
              :ratingCheckboxes="ratingCheckboxes"
              :countryCheckboxes="countryCheckboxes"
              :bathroomCheckboxes="bathroomCheckboxes"
              :bedroomCheckboxes="bedroomCheckboxes"
              :handleStageReset="handleStageReset"
              :expandFiltersMap="expandFiltersMap"
              :handleCountryCheckboxClick="handleCountryCheckboxClick"
              :handleCountryReset="handleCountryReset"
              :handleBathroomCheckboxClick="handleBathroomCheckboxClick"
              :handleRatingCheckboxClick="handleRatingCheckboxClick"
              :handleBathroomReset="handleBathroomReset"
              :handleBedroomCheckboxClick="handleBedroomCheckboxClick"
              :handleDocumentsCheckboxClick="handleDocumentsCheckboxClick"
              :handleBedroomReset="handleBedroomReset"
              :updateSliderFilter="updateSliderFilter"
              :resetSliderFilter="resetSliderFilter"
              :isFilterLoader="isFilterLoader"
              :property-checkboxes="properyTypeCheckboxes"
              :handlePropertyTypeCheckboxClick="handlePropertyTypeCheckboxClick"
              :property-type="selectedPropertyType[0]"
              :handleCompanyReset="handleCompanyReset"
              :documentsCheckboxes="documentsCheckboxes"
              :handleRatingReset="handleRatingReset"
            />
          </search-mobile-filter>
        </keep-alive>
      </div>
    </div>
    <div>
      <h5 class="font--subtitle-2-bold text-black-monochrome nav-title">
        {{ $t("search_filters.fr_more") }}
      </h5>
      <base-search-desktop
        class="search-desktop"
        @typing="debouncedHandleMobilSearch"
        :search="search"
      />
      <base-separator class="search-separator" />
      <base-navigator
        :handleStageCheckboxClick="handleStageCheckboxClick"
        :ratingCheckboxes="ratingCheckboxes"
        :handleCompanyCheckboxClick="handleCompanyCheckboxClick"
        :stageCheckboxes="stageCheckboxes"
        :companyCheckboxes="companyCheckboxes"
        :countryCheckboxes="countryCheckboxes"
        :bathroomCheckboxes="bathroomCheckboxes"
        :bedroomCheckboxes="bedroomCheckboxes"
        :handleStageReset="handleStageReset"
        :expandFiltersMap="expandFiltersMap"
        :handleCountryCheckboxClick="handleCountryCheckboxClick"
        :handleCountryReset="handleCountryReset"
        :handleBathroomCheckboxClick="handleBathroomCheckboxClick"
        :handleRatingCheckboxClick="handleRatingCheckboxClick"
        :handleBathroomReset="handleBathroomReset"
        :handleRatingReset="handleRatingReset"
        :handleBedroomCheckboxClick="handleBedroomCheckboxClick"
        :handleBedroomReset="handleBedroomReset"
        :handleDocumentsCheckboxClick="handleDocumentsCheckboxClick"
        :updateSliderFilter="updateSliderFilter"
        :resetSliderFilter="resetSliderFilter"
        :isFilterLoader="isFilterLoader"
        :dropAllFilters="dropAllFilters"
        :property-checkboxes="properyTypeCheckboxes"
        :handlePropertyTypeCheckboxClick="handlePropertyTypeCheckboxClick"
        :property-type="selectedPropertyType[0]"
        :documentsCheckboxes="documentsCheckboxes"
        :handleCompanyReset="handleCompanyReset"
      />
    </div>
    <div class="wrapper">
      <span class="search-view">
        <span class="sorting">
          <h5 class="font--b2-3 text-black-monochrome">
            {{ objsAmount || 0 }} {{ $t("search_filters.fr_found") }}
          </h5>
          <base-button
            v-if="!$device.isMobile"
            ref="sortButtRef"
            :text="$t('search_filters.fr_sort')"
            size="xss"
            variant="light_bordered"
            icon-name="base/Sliders"
            icon-size="16px"
            :class="{ 'sort-active': Object.keys(orderFilters?.order).length }"
          />
        </span>

        <div>
          <q-menu
            :target="sortButtRef"
            class="sorting-menu"
            anchor="bottom left"
            self="top left"
            :offset="[0, 10]"
          >
            <div class="menu">
              <h3
                class="font--b4 text-black-monochrome"
                style="font-weight: 700"
              >
                {{ $t("search_filters.fr_sort") }}
              </h3>
              <div
                v-for="(item, index) in sortingFilters"
                class="list-container"
              >
                <ul>
                  <li>
                    <button
                      v-close-popup
                      style="justify-content: flex-start; gap: 12px"
                      @click="sortEstates(item)"
                    >
                      <p
                        class="font--b4 text-black-monochrome"
                        :style="{
                          fontWeight: item.asc || item.desc ? 'bold' : 'normal',
                        }"
                      >
                        {{ item.label }}
                      </p>
                      <base-icon
                        size="22px"
                        :name="`base/${
                          item.asc || item.desc ? 'ArrowDown' : 'ArrowsDownUp'
                        }`"
                        :rot="item.asc ? '0.5turn' : '0deg'"
                        :color="
                          item.asc || item.desc
                            ? 'var(--black-monochrome)'
                            : 'var(--black-monochrome-60)'
                        "
                      />
                    </button>
                  </li>
                </ul>
                <q-separator
                  v-if="sortingFilters.length !== index + 1"
                  style="width: 100%"
                />
              </div>
            </div>
          </q-menu>
        </div>
        <span v-if="!$device.isMobile" class="view-changer">
          <button
            :class="{ active: currentView === 'list' }"
            @click="changeSearchView('list')"
          >
            <base-icon
              name="base/Menu"
              size="16px"
              :color="currentView === 'list' ? 'white' : 'black'"
            />
          </button>
          <button :class="{ active: currentView === 'grid' }">
            <base-icon
              name="base/Grid"
              size="20px"
              filled
              @click="changeSearchView('grid')"
            />
          </button>
        </span>
      </span>
      <div
        v-show="!skeletonLoader"
        class="search-container animate__animated animate__fadeIn"
      >
        <Transition v-if="!skeletonLoader" name="fade">
          <div v-if="currentView === 'list'" class="search-container__list">
            <base-search-card
              v-for="item in searchItemsComputed"
              :property-info="item"
              :is-sold="isSold(item.token.availableSupply)"
              :property-type="selectedPropertyType[0]"
              @click-provider="handleCompanyCheckboxClick"
            />
          </div>
          <div v-else class="search-container__grid">
            <base-search-card-new
              v-for="item in itemsArray"
              :estate="item"
              size="search"
              :property-type="selectedPropertyType[0]"
              @click-provider="handleCompanyCheckboxClick"
            />
          </div>
        </Transition>
        <search-page-no-objects v-if="isNoData">
          <template #default>
            <h3 class="font--h4-2 mobile-font--h4 text-black-monochrome">
              {{ $t("search_filters.fr_no_object.no_result") }}
            </h3>
            <p class="font--b2 mobile-font--b2 text-black-monochrome">
              {{ $t("search_filters.fr_no_object.couldnt_find") }}
            </p>
          </template>
        </search-page-no-objects>
        <base-paginator
          :limit="limit"
          :offset="offset"
          :items-amount="objsAmount ?? 0"
          @change="onPaginator"
        />
      </div>
      <div
        v-show="skeletonLoader"
        class="search-container animate__animated animate__fadeIn"
        :class="{
          'search-container__list': currentView === 'list',
          'search-container__grid': currentView === 'grid',
        }"
        style="height: fit-content"
      >
        <q-skeleton
          v-for="q in Array(3).fill(null)"
          class="search-skeleton"
          :class="{
            'search-skeleton__grid': currentView === 'grid',
            'search-skeleton__list': currentView === 'list',
          }"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { filtersStore } from "~/store/search_filters";
import getRoute from "~/utilities/configs/apiMap";
import { apiPost } from "~/services/api";
import type { PropertyItem } from "~/types/search-page-items";
import eventBus from "~/utilities/composables/eventBus";
// import { PopUpServices } from "~/services/PopUp/callPopUp";
// import { cleanQuery } from "~/utilities/helpers/query/cleanQuery";
import { Notification } from "~/services/notifications/toast";
import { updateSearchPageSeo } from "~/services/SEO/updateSearchPageSeo";
import { transformQueryToObject } from "~/utilities/helpers/query/transformQueryToObject";
// import { serializeObject } from "~/utilities/helpers/query/serializeObject";
// import { countryNames } from "~/utilities/constants/countryCodes";
import type {
  FilterCheckbox,
  ExpandFiltersMap,
  CanResetAnim,
  PropertyTypes,
} from "~/components/base/navigator/types";
import { serializeObject } from "~/utilities/helpers/query/serializeObject";
import { debounce } from "lodash";
import { userStore } from "~/store/user";
import { isEqual } from "lodash";
import { useAdminStore } from "~/store/admin";
import type { PartnerResponseDTO } from "~/services/swagger/Api";
import {
  convertServerStars,
  getServerStars,
} from "~/utilities/helpers/review/convertServerRating";

definePageMeta({
  layout: "default",
});

updateSearchPageSeo();

type FilterItem = {
  label: string;
  value: string[];
  asc: boolean;
  desc: boolean;
  id: number;
};

// const usePopUpServices = PopUpServices();
const useFiltersStore = filtersStore();
const useUserStore = userStore();
const adminStore = useAdminStore();
const route = useRoute();
const router = useRouter();
const holders = adminStore.getHoldersClass();
// const localePath = useLocalePath();
const { t } = useI18n();

const itemsArray = ref<PropertyItem[]>([]);
const searchItemsComputed = computed(() => itemsArray.value);
const limit = ref(9);
const offset = ref(0);
const skeletonLoader = ref(false);
const isInitialLoading = ref(false);
const isFilterLoader = ref(false);
const showFiltersSugg = ref(false);
const isLastPage = ref(false);
const isNoData = ref(false);
const isSold = (availableSupply: string | null | undefined) => !availableSupply;
const objsAmount = ref<number | null>(0);
const currentView = ref<"list" | "grid">("list");
const sortButtRef = ref<HTMLElement | null>(null);
const orderFilters = ref<Record<string, string | any>>({ order: {} });
const previousFilters = ref<Record<string, any>>();
// type CanResetAnim = "show" | "hide" | "init";
const expandFiltersMap = reactive<ExpandFiltersMap>({
  price_range: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  min_invest: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  irr: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  apr: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  bedroom: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  bathroom: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  countryId: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  stage: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  property_type: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  providerIds: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  documents: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
  rating: {
    expanded: true,
    canReset: "init" as CanResetAnim,
  },
});

const sortingFilters = ref([
  {
    label: t("search_filters.fr_most_viewd"),
    value: ["viewsForAllTime"],
    asc: false,
    desc: false,
    id: 1,
  },
  {
    label: t("search_card.fr_price"),
    value: ["priceUSD"],
    asc: false,
    desc: false,
    id: 2,
  },
  {
    label: t("search_filters.fr_price"),
    value: ["token", "priceUSD"],
    asc: false,
    desc: false,
    id: 3,
  },
  {
    label: t("search_card.fr_apr"),
    value: ["apr"],
    asc: false,
    desc: false,
    id: 4,
  },
]);

const sortingFiltersMobile = ref([
  {
    label: `${t("search_card.fr_price")} : ${t("search_filters.desc")}`,
    value: ["priceUSD"],
    asc: false,
    desc: true,
    id: 1,
  },
  {
    label: `${t("search_card.fr_price")} : ${t("search_filters.asc")}`,
    value: ["priceUSD"],
    asc: true,
    desc: false,
    id: 2,
  },
  {
    label: `${t("search_card.fr_token")} : ${t("search_filters.asc")}`,
    value: ["token", "priceUSD"],
    asc: true,
    desc: false,
    id: 3,
  },
  {
    label: `${t("search_card.fr_token")} : ${t("search_filters.desc")}`,
    value: ["token", "priceUSD"],
    asc: false,
    desc: true,
    id: 4,
  },
  {
    label: `${t("search_card.fr_apr")}`,
    value: ["apr"],
    asc: false,
    desc: false,
    id: 5,
  },
]);

const selectedFilterMobile = ref<
  | {}
  | {
      label: string;
      value: string[];
      asc: boolean;
      desc: boolean;
      id: number;
    }
>({});

// const allFilters = ref<Record<string, any>>({});
const selectedRooms = ref<string[]>([]);
const selectedCompanies = ref<string[]>([]);
const selectedRating = ref<string[]>([]);
const selectedBathRooms = ref<string[]>([]);
const selectedCountry = ref<string[]>([]);
const selectedStage = ref<string[]>([]);
const selectedDocuments = ref<string[]>([]);
const selectedPropertyType = ref<PropertyTypes[]>(["estates"]);
const selectedDocument = ref(false);
const search = ref<string>("");

const getFilterCheckboxes = (args: {
  serverObj: Record<string, any> | null | undefined;
  values: Ref<string[]>;
  labelAlone: string;
  labelMany?: string;
  stringKeys?: boolean;
  otherLable?: string;
  // serverLabel?: string;
  // labelsObj?: Record<string, any>;
}) => {
  if (!args.serverObj) return [];
  const checkboxes: FilterCheckbox[] = [];
  let othersCount = 0;

  Object.entries(args.serverObj).forEach(([key, value]) => {
    const checkboxNumber = parseInt(key, 10);

    if (
      !args.stringKeys &&
      (isNaN(checkboxNumber) || checkboxNumber < 1 || checkboxNumber > 5)
    ) {
      othersCount += value;
    } else {
      const label = args.stringKeys
        ? key
        : `${key} ${
            checkboxNumber === 1
              ? args.labelAlone
              : (args.labelMany ?? args.labelAlone)
          }`;
      checkboxes.push({
        label: label,
        count: value,
        checked: args.values.value.includes(key),
        server_label: key,
      });
    }
  });

  if (othersCount > 0) {
    checkboxes.push({
      label: args.otherLable ?? "Others",
      count: othersCount,
      checked: args.values.value.includes("null"),
      server_label: "null",
    });
  }

  return reactive(checkboxes);
};

// const checkIsCheckboxPlayGte = (val: Object | string[] | number[]) => {
//   if (typeof val === "object") {
//     return convertRangeToArray(val as { gte: string; lte: string });
//   } else {
//     return val;
//   }
// };

// const convertRangeToArray = (range: { gte: string; lte: string }): string[] => {
//   const min = Math.min(Number(range.gte), Number(range.lte));
//   const max = Math.max(Number(range.gte), Number(range.lte));
//   const values = Array.from(
//     { length: Math.floor(max) - Math.floor(min) + 1 },
//     (_, i) => (Math.floor(min) + i).toString()
//   );
//   return values;
// };

const companiesRef = ref();
const companyCheckboxes = computed(() => {
  if (!adminStore.$state.providers) return [];
  const boxes = getFilterCheckboxes({
    serverObj: companiesRef.value,
    values: selectedCompanies,
    labelAlone: "",
    stringKeys: true,
  });

  boxes.forEach((item) => {
    item.server_label =
      adminStore.$state.providers.find(
        (q) =>
          q.name.toLowerCase().replace(/\s+/g, "") ===
          item.server_label.toLowerCase().replace(/\s+/g, "")
      )?.id ?? item.server_label;
    item.checked = selectedCompanies.value.includes(item.server_label);
    item.count =
      useFiltersStore.currentStats?.provider[
        item.server_label as keyof typeof useFiltersStore.currentStats.provider
      ] || 0;
  });
  return boxes;
});

const bedroomCheckboxes = computed(() =>
  getFilterCheckboxes({
    serverObj: useFiltersStore.savedStats?.bedroom,
    values: selectedRooms,
    labelAlone: t("search_filters.fr_bed"),
    labelMany: t("search_filters.fr_beds"),
  })
);
const ratingCheckboxes = computed(() =>
  getFilterCheckboxes({
    serverObj: useFiltersStore.savedStats?.rating,
    values: selectedRating,
    labelAlone: "rating",
    otherLable: "No ratings",
  })
);

const documentsCheckboxes = computed(
  () =>
    [
      {
        label: t("search_filters.documents"),
        count: useFiltersStore.currentStats?.hasDocuments["true"],
        checked: selectedDocument.value,
        server_label: "hasDocument",
        filterUpdate: useFiltersStore.updateEstateFilters,
      },
    ] as FilterCheckbox[]
);

const bathroomCheckboxes = computed(() =>
  getFilterCheckboxes({
    serverObj: useFiltersStore.savedStats?.bathroom,
    values: selectedBathRooms,
    labelAlone: t("search_filters.fr_bath"),
    labelMany: t("search_filters.fr_baths"),
  })
);

const countryCheckboxes = computed(() =>
  getFilterCheckboxes({
    serverObj: useFiltersStore.savedStats?.country,
    values: selectedCountry,
    labelAlone: t("compare.country"),
    labelMany: t("compare.country"),
    stringKeys: true,
  })
);

const stageCheckboxes = computed(() =>
  getFilterCheckboxes({
    serverObj: useFiltersStore.savedStats?.stage,
    values: selectedStage,
    labelAlone: t("search_filters.fr_stage"),
    stringKeys: true,
  })
);

const properyTypeCheckboxes = computed(
  () =>
    [
      {
        label: t("search_filters.fr_property_type.estate"),
        count: useFiltersStore.currentStats?.total || 0,
        checked: selectedPropertyType.value.includes("estates"),
        server_label: "estates",
        filterUpdate: useFiltersStore.updateEstateFilters,
      },
      // {
      //   label: t("search_filters.fr_property_type.land"),
      //   count: useFiltersStore.kostylLandsCount,
      //   checked: selectedPropertyType.value.includes("lands"),
      //   server_label: "lands",
      //   filterUpdate: useFiltersStore.updateLandFilters,
      // },
    ] as FilterCheckbox[]
);

const fetchSearchPage = async (filters?: any) => {
  skeletonLoader.value = true;
  // switchLoader();
  // if (clearArray) {
  //   itemsArray.value = [];
  // }
  isNoData.value = false;
  filters = transformQueryToObject(filters);

  if (Object.keys(orderFilters.value?.order).length) {
    filters = { ...filters, ...orderFilters.value };
  } else {
    delete filters.order;
  }
  let cleanedFilters = filters;
  Object.keys(cleanedFilters).forEach((key) => {
    if (
      cleanedFilters["countryId"] &&
      Array.isArray(cleanedFilters["countryId"]) &&
      !cleanedFilters["countryId"].length
    ) {
      delete cleanedFilters["countryId"];
    }
  });

  const url = getRoute({ endpont: "post_units" });
  const data = await apiPost({
    url: url,
    body: { limit: limit.value, offset: offset.value, ...cleanedFilters },
    headers: {
      Authorization:
        useUserStore.role === "admin"
          ? null
          : `Bearer ${useCookie("acc_token").value}`,
    },
  }).finally(() => {
    setTimeout(() => {
      skeletonLoader.value = false;
    }, 500);
  });
  if (!data?.data?.value) {
    isNoData.value = true;
    isLastPage.value = true;
    objsAmount.value = 0;
    return;
  }
  const response = data?.data.value as {
    count: number | null;
    rows: PropertyItem[] | null;
  };

  if (response.rows?.length) {
    itemsArray.value = response.rows;
  } else {
    isNoData.value = true;
    itemsArray.value = [];
  }
  objsAmount.value = response.count;
};

const updateFilters = async (noLoader?: boolean, obj?: Record<string, any>) => {
  if (!noLoader) {
    isFilterLoader.value = true;
  }
  const params = obj ?? transformQueryToObject(route.query);
  const res = await useFiltersStore.updateEstateFilters(
    isInitialLoading.value,
    params
  );
  reCountCheckboxes();
  setTimeout(() => {
    isFilterLoader.value = false;
  }, 500);
};

const reCountCheckboxes = () => {
  const updateableKeys: string[] = ["bedroom", "bathroom", "country", "stage"];
  updateableKeys.forEach((key) => {
    const savedStats =
      useFiltersStore.savedStats[
        key as keyof typeof useFiltersStore.savedStats
      ] || {};
    const currentStats =
      useFiltersStore.currentStats?.[
        key as keyof typeof useFiltersStore.currentStats
      ] || {};

    // Обновляем savedStats значениями из currentStats или 0, если ключа нет
    const updatedStats = Object.fromEntries(
      Object.keys(savedStats).map((k) => [k, currentStats[k] ?? 0])
    );

    // Сохраняем обновленные значения обратно в savedStats
    useFiltersStore.savedStats[key] = updatedStats;
  });
};

const updateSliderFilter = async (
  key: keyof typeof expandFiltersMap,
  paramName: string,
  values: Record<string, Record<string, number | string>>
) => {
  const nestedValues = values[paramName];
  const queryParams = Object.fromEntries(
    Object.entries(nestedValues).map(([subKey, value]) => [
      `${paramName}[${subKey}]`,
      value.toString(),
    ])
  );

  const query = {
    ...route.query,
    ...queryParams,
  };
  router.push({
    query,
  });

  expandFiltersMap[key].canReset = "show";
  await fetchSearchPage(query);
  updateFilters(true, transformQueryToObject(query));
};

const resetSliderFilter = async (
  key: keyof typeof expandFiltersMap,
  paramName: string,
  resetValues: number[],
  isDrop?: boolean
) => {
  if (expandFiltersMap[key].canReset === "hide") return;

  expandFiltersMap[key].canReset = "hide";

  if (!isDrop) {
    const obj: Record<string, any> = {};
    Object.keys(route.query).forEach((key) => {
      if (!key.includes(paramName)) {
        obj[key] = route.query[key];
      }
    });

    router.push({
      query: { ...obj },
    });

    await fetchSearchPage(obj);
    updateFilters(true, transformQueryToObject(obj));
  }

  eventBus.emit(`drop-${paramName}-range`, resetValues);
};

const handleBedroomCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("bedroom", item, selectedRooms);
  ifCanResetCheckbox(selectedRooms, "bedroom");
};

const handleBathroomCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("bathroom", item, selectedBathRooms);
  ifCanResetCheckbox(selectedBathRooms, "bathroom");
};

const handleRatingCheckboxClick = (item: FilterCheckbox) => {
  console.log("item: ", item);

  updateCheckboxFilter("rating", item, selectedRating);
  ifCanResetCheckbox(selectedRating, "rating");
};

const handleCompanyCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("providerIds", item, selectedCompanies);
  ifCanResetCheckbox(selectedCompanies, "providerIds");
};

const handleCountryCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("countryId", item, selectedCountry);
  ifCanResetCheckbox(selectedCountry, "countryId");
};

const handleStageCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("stage", item, selectedStage);
  ifCanResetCheckbox(selectedStage, "stage");
};

const handleDocumentsCheckboxClick = (item: FilterCheckbox) => {
  updateCheckboxFilter("hasDocument", item, selectedDocuments);
  // ifCanResetCheckbox(selectedRooms, "bedroom");
};

const handlePropertyTypeCheckboxClick = async (item: FilterCheckbox) => {
  if (selectedPropertyType.value.includes(item.server_label as PropertyTypes))
    return;

  isFilterLoader.value = true;
  const currentRouterQuery = transformQueryToObject(route.query);
  dropAllFilters(true);
  const queryString = serializeObject(previousFilters.value);
  const queryObject = queryString
    ? Object.fromEntries(new URLSearchParams(queryString))
    : {};
  router
    .replace({
      path: router.currentRoute.value.path,
      query: queryObject,
    })
    .then(() => {
      nextTick(async () => {
        offset.value = 0;
        previousFilters.value = currentRouterQuery;
        selectedPropertyType.value = [];
        itemsArray.value = [];
        selectedPropertyType.value.push(item.server_label as PropertyTypes);
        fetchSearchPage(previousFilters.value);
        if (item.filterUpdate) {
          await item.filterUpdate(true, previousFilters.value);
        }

        isFilterLoader.value = false;
        ifCheckboxChecked();
        ifCanResetSliders();
      });
    });
};

const handleStageReset = () => {
  resetCheckboxFilter("stage", selectedStage);
};

const handleCountryReset = () => {
  resetCheckboxFilter("countryId", selectedCountry);
};

const handleBathroomReset = () => {
  resetCheckboxFilter("bathroom", selectedBathRooms);
};
const handleRatingReset = () => {
  resetCheckboxFilter("rating", selectedRating);
};
const handleCompanyReset = () => {
  resetCheckboxFilter("providerIds", selectedCompanies);
};

const handleBedroomReset = () => {
  resetCheckboxFilter("bedroom", selectedRooms);
};

const ifCanResetCheckbox = (
  arr: Ref<string[]>,
  key: keyof typeof expandFiltersMap
) => {
  if (!arr || !arr.value || !arr.value.length) {
    expandFiltersMap[key].canReset = "hide";
  } else {
    expandFiltersMap[key].canReset = "show";
  }
};

const resetCheckboxFilter = async (
  key: keyof typeof expandFiltersMap,
  values: Ref<string[]>,
  isDrop?: boolean
) => {
  expandFiltersMap[key].canReset = "hide";
  values.value = [];
  if (!isDrop) {
    const query = getCheckboxFilterQuery({
      paramName: key,
      array: values.value,
    });
    router.push({ query });
    await fetchSearchPage(query);
    updateFilters(true, transformQueryToObject(route.query));
  }
};

const updateCheckboxFilter = async (
  paramName: string,
  value: FilterCheckbox,
  array: Ref<string[]>
) => {
  const standAloneParams = ["hasDocument"];
  value.checked = !value.checked;
  if (array.value.includes(value.server_label)) {
    array.value = array.value.filter((v) => v !== value.server_label);
  } else {
    array.value.push(value.server_label);
  }
  let newArray: string[] = [];
  if (paramName === "rating") {
    newArray = array.value.map((q) => {
      if (q === "null" || q == null) return q;
      return getServerStars(parseInt(q));
    });
  } else {
    newArray = array.value;
  }
  console.log(' newArray: ',  newArray);

  const query = getCheckboxFilterQuery({
    paramName: paramName,
    array: newArray,
    standAlone: standAloneParams.includes(paramName),
    standValue: value.checked,
  });
  console.log('query: ', query);


  router.push({ query });

  await fetchSearchPage(query);
  updateFilters(true, transformQueryToObject(query));
};

// const updateRatingFilter = async (
//   value: FilterCheckbox,
//   array: Ref<string[]>
// ) => {
//   value.checked = !value.checked;

//   if (array.value.includes(value.server_label)) {
//     array.value = array.value.filter((v) => v !== value.server_label);
//   } else {
//     array.value.push(value.server_label);
//   }

//   const numericArray = array.value.map(Number).filter((v) => !isNaN(v));

//   const queryParams = {
//     [`rating[gte]`]:
//       numericArray.length > 0
//         ? (Math.max(...numericArray) + 0.9).toString()
//         : "",
//     [`rating[lte]`]:
//       numericArray.length > 0 ? Math.min(...numericArray).toString() : "",
//   };

//   const query = {
//     ...route.query,
//     ...queryParams,
//   };

//   router.push({ query });

//   await fetchSearchPage(query);
//   updateFilters(true, transformQueryToObject(query));
// };

const getCheckboxFilterQuery = (args: {
  paramName: string;
  array: string[];
  standAlone?: boolean;
  standValue?: any;
}) => {
  if (!args.standAlone) {
    const cleanedQuery = Object.fromEntries(
      Object.entries(route.query).filter(
        ([key]) => !key.startsWith(`${args.paramName}[`)
      )
    );

    const queryParams = args.array.map((k, index) => [
      `${args.paramName}[${index}]`,
      k === "null"
        ? k
        : !isNaN(parseInt(Number(k)))
          ? parseInt(Number(k))
          : k,
    ]);

    const query = {
      ...cleanedQuery,
      ...Object.fromEntries(queryParams),
    };
    return query;
  } else {
    const query = {
      ...route.query,
      [args.paramName]: args.standValue,
    };
    return query;
  }
};

const onPaginator = async (newOffset: number) => {
  offset.value = newOffset;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  itemsArray.value = [];
  fetchSearchPage(route.query);
};

const checkVisit = () => {
  if (Object.values(route.query || {}).length === 0) {
    sessionStorage.setItem("search-page-visited", "1");
    return false;
  }

  const isVisited = sessionStorage.getItem("search-page-visited");

  let visitCount = 0;

  if (isVisited) {
    const visitCountParsed = parseInt(isVisited);
    visitCount = isNaN(visitCountParsed) ? 0 : visitCountParsed + 1;
  } else {
    visitCount = 1;
  }

  showFiltersSugg.value = visitCount >= 7;

  sessionStorage.setItem("search-page-visited", visitCount.toString());

  if (showFiltersSugg.value) {
    showFiltersSugg.value = false;
    router.replace({ query: {} });
    const notifInst = new Notification({ type: "system" });
    notifInst.createNewToast(
      "We noticed you were refreshing the page many times, so we cleaned all filters."
    );
    sessionStorage.setItem("search-page-visited", "1");
    return true;
  }

  return false;
};

const changeSearchView = async (type: "list" | "grid") => {
  skeletonLoader.value = true;
  currentView.value = type;
  setTimeout(() => {
    skeletonLoader.value = false;
  }, 1000);
};

const sortEstates = async (item: FilterItem) => {
  // Determine the current sorting state and cycle through them
  if (!item.asc && !item.desc) {
    // Set to descending if neither is active
    item.desc = true;
    item.asc = false;
  } else if (item.desc) {
    // Switch to ascending if descending is active
    item.desc = false;
    item.asc = true;
  } else if (item.asc) {
    // Clear sorting if ascending is active
    item.asc = false;
    item.desc = false;
  }

  // Clear sorting for other filters
  sortingFilters.value.forEach((filter) => {
    if (filter.id !== item.id) {
      filter.asc = false;
      filter.desc = false;
    }
  });

  // Update the order filters based on the current sorting state
  sortingFilters.value.forEach((filter) => {
    const key = filtersKeyMap(filter.value[0]);
    if (filter.asc || filter.desc) {
      orderFilters.value.order[key] = filter.asc ? "asc" : "desc";
    } else {
      delete orderFilters.value.order[key];
    }
  });

  // Fetch updated results with the current filters
  await gatherCompanies();
  await fetchSearchPage(route.query);
};

const filtersKeyMap = (key: string) => {
  switch (key) {
    case "token":
      return "tokenomics.priceUSD";
    case "apr":
      return "estate.apr";
    default:
      return key;
  }
};

const sortEstatesMobile = async (filter: {
  label: string;
  value: string[];
  asc: boolean;
  desc: boolean;
  id: number;
}) => {
  orderFilters.value.order = {};

  const key = filtersKeyMap(filter.value[0]);
  if (filter.asc || filter.desc || key === "estate.apr") {
    orderFilters.value.order[key] = filter.asc ? "asc" : "desc";
  }
  if (isEqual(selectedFilterMobile.value, filter)) {
    orderFilters.value.order = { viewsForAllTime: "desc" };
    selectedFilterMobile.value = {};
  } else {
    selectedFilterMobile.value = filter;
  }
  await fetchSearchPage(route.query);
};

const ifCheckboxChecked = () => {
  const updateableKeys: {
    key: keyof typeof expandFiltersMap;
    arr: Ref<string[]>;
  }[] = [
    { key: "bedroom", arr: selectedRooms },
    { key: "bathroom", arr: selectedBathRooms },
    { key: "countryId", arr: selectedCountry },
    { key: "stage", arr: selectedStage },
    { key: "providerIds", arr: selectedCompanies },
    { key: "rating", arr: selectedRating },
  ];

  const obj = transformQueryToObject(route.query);
  updateableKeys.forEach((item) => {
    if (!obj[item.key]) return;
    if (item.key === "rating" && Array.isArray(obj[item.key])) {
      obj[item.key] = obj[item.key].map((q) => convertServerStars(q));
    }
    expandFiltersMap[item.key].canReset = "show";
    if (Array.isArray(obj[item.key])) {
      obj[item.key] = obj[item.key].map((item: string | number | null) =>
        String(item).toString()
      );
    }
    item.arr.value = obj[item.key];
    // item.arr.value = checkIsCheckboxPlayGte(obj[item.key]);
  });
  selectedDocument.value = obj?.hasDocument;
};

const ifCanResetSliders = () => {
  const resetableKeys: {
    q_key: string;
    f_key: keyof typeof expandFiltersMap;
  }[] = [
    { q_key: "irr", f_key: "irr" },
    { q_key: "apr", f_key: "apr" },
    { q_key: "minimumInvestment", f_key: "min_invest" },
    { q_key: "tokenPrice", f_key: "price_range" },
    { q_key: "providerIds", f_key: "providerIds" },
  ];
  const queryObject = transformQueryToObject(route.query);
  resetableKeys.forEach((t) => {
    if (queryObject[t.q_key]) {
      expandFiltersMap[t.f_key].canReset = "show";
    }
  });
};

const dropAllFilters = async (noUpdate?: boolean) => {
  router.replace("/directory");
  resetCheckboxFilter("stage", selectedStage, true);
  resetCheckboxFilter("rating", selectedRating, true);
  resetCheckboxFilter("countryId", selectedCountry, true);
  resetCheckboxFilter("bathroom", selectedBathRooms, true);
  resetCheckboxFilter("bedroom", selectedRooms, true);
  resetCheckboxFilter("providerIds", selectedCompanies, true);
  resetSliderFilter(
    "price_range",
    "tokenPrice",
    [
      useFiltersStore.savedStats?.tokenPriceMin!,
      useFiltersStore.savedStats?.tokenPriceMax!,
    ],
    true
  );
  resetSliderFilter(
    "min_invest",
    "minimumInvestment",
    [
      useFiltersStore.savedStats?.minimumInvestment ?? 0,
      useFiltersStore.savedStats?.maximumInvestment ?? 100,
    ],
    true
  );
  resetSliderFilter(
    "irr",
    "irr",
    [
      useFiltersStore.savedStats?.irrMin ?? 0,
      useFiltersStore.savedStats?.irrMax ?? 100,
    ],
    true
  );
  resetSliderFilter(
    "apr",
    "apr",
    [
      useFiltersStore.savedStats?.aprMin ?? 0,
      useFiltersStore.savedStats?.aprMax ?? 100,
    ],
    true
  );
  Object.keys(expandFiltersMap).forEach((key) => {
    expandFiltersMap[key as keyof typeof expandFiltersMap].canReset = "init";
  });
  if (
    typeof noUpdate !== "boolean" ||
    (typeof noUpdate === "boolean" && !noUpdate)
  ) {
    await fetchSearchPage({});
    updateFilters(true, {});
  }
  nextTick(() => {
    eventBus.emit("clear-directory-search");
  });
};

const ifSearchQuery = () => {
  const searchQuery = route.query.search as string | undefined;
  if (searchQuery) {
    search.value = searchQuery;
  }
};

const handleMobilSearch = (val: string) => {
  search.value = val;
  const newFilters = { ...route.query, search: val };
  router.replace({ query: newFilters });

  fetchSearchPage(newFilters);
  updateFilters(true, transformQueryToObject(newFilters));
};

const debouncedHandleMobilSearch = debounce((val: string) => {
  handleMobilSearch(val);
}, 300);

const gatherCompanies = async () => {
  const res = await adminStore.getProviders();
  if (!res) return {};
  let newObj: Partial<PartnerResponseDTO> = {};
  res.forEach((item) => {
    const obj = { [item.name]: item.unitCount || 0 };
    newObj = { ...newObj, ...obj };
  });

  companiesRef.value = newObj;
};

onMounted(async () => {
  // const isVisited = checkVisit();
  isInitialLoading.value = true;
  await sortEstates(sortingFilters.value[0]);
  // await fetchSearchPage(isVisited ? {} : route.query);
  await updateFilters(false, {});

  // useFiltersStore.kostylUpdateLandsCount();
  isInitialLoading.value = false;
  updateFilters(false, transformQueryToObject(route.query));
  ifCheckboxChecked();
  ifCanResetSliders();
  ifSearchQuery();
});
onUnmounted(() => {
  sessionStorage.setItem("search-page-visited", "1");
});
</script>

<style scoped lang="scss">
.page-content {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 990px;
  margin-bottom: 50px;
  padding-top: 29px;
  display: grid;
  grid-template-columns: 190px 1fr;
  gap: 86px;
  .wrapper {
    display: grid;
    gap: 24px;
    height: fit-content;
  }
  .search-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .sorting {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
    }
    .view-changer {
      display: inline-flex;
      padding: 5px;
      align-items: center;
      gap: 4px;
      border-radius: 20px;
      border: 1px solid var(--gray-monochrome-40);
      background: var(--white-contrast);
      button {
        @include drop-button-styles;
        display: flex;
        width: 28px;
        height: 28px;
        padding: 4px;
        align-items: center;
        gap: 4px;
        border-radius: 50%;
        transition: background-color 0.3s ease;
        justify-content: center;
      }
      .active {
        background-color: var(--black-monochrome);
        :deep(.icon) {
          svg {
            path {
              stroke: white;
            }
          }
        }
      }
    }
  }
  .nav-title {
    position: relative;
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 2px solid var(--black-monochrome);
  }
  .search-container {
    position: relative;
    height: fit-content;
    display: grid;
    gap: 24px;
    justify-items: center;
    &__list {
      width: 100%;
      display: grid;
      align-items: self-start;
      justify-items: end;
      grid-template-columns: 1fr;
      gap: 24px;
    }
    &__grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
      gap: 20px;
    }

    .filter-loader {
      top: -1rem;
      left: -10px;
      align-items: flex-start;
      :deep(.loader) {
        margin-top: 15rem;
      }
    }
    @media (max-width: 2598px) {
      &__list {
        justify-items: center;
      }
      max-width: 100vw;
    }
    // @media (min-width: 2600px) {
    //   &__list {
    //     grid-template-columns: 1fr 1fr;
    //   }
    //   width: 100%;
    // }

    // @media (min-width: 3860px) {
    //   &__list {
    //     grid-template-columns: 1fr 1fr 1fr;
    //   }
    //   width: 100%;
    // }
  }

  .search-mobile {
    display: none;
  }
  :deep(.base-search) {
    margin-bottom: 12px;
  }
  .search-separator {
    display: block;
    margin-bottom: 6px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
.sort-active {
  background-color: var(--white-transparent);
  border-color: var(--black-monochrome-72);
}

.search-skeleton {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--shadow-light);
  &__grid {
    width: 229px;
    height: 514px;
  }
  &__list {
    width: 735px;
    height: 326px;
  }
  @media (max-width: 1430px) {
    &__list {
      width: 100%;
    }
  }
}

@media (max-width: 75rem) {
  .page-content {
    padding-top: 24px;
    grid-template-columns: 1fr;
    gap: 0;

    .search-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 24px;
      padding-right: 24px;
      gap: 24px;
      padding-right: 24px;
      :deep(.base-search) {
        min-width: 277px;
      }
      &__btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .btn-sort {
          padding: 19px 15px;
        }
      }
    }
    .nav-title {
      display: none;
    }
    .search-separator {
      display: none;
    }
  }

  .search-desktop {
    display: none;
  }

  :deep(.nav-container) {
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid var(--white-contrast);
    margin: 0 0 0 24px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .base-navigator {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      max-width: 100%;
      padding: 32px 0 0;
      gap: 0;
      &--controls {
        display: none;
      }
      .rest-nav {
        display: none;
      }
    }
  }

  :deep(.base-nav--item) {
    padding: 0 12px;
    &:first-child {
      padding-left: 0;
    }
    &:nth-child(4) {
      padding-right: 24px;
    }
    justify-self: center;
    .item-wrapper {
      white-space: nowrap;
      min-width: unset;
      .badge-wrapper {
        width: 100%;
        height: auto;
        gap: 0;
        flex-direction: column;
        .item-badge {
          order: 1;
          width: 100%;
          height: 3px;
          border-radius: 10px 10px 0 0;
        }
        .item-link {
          padding: 0;
        }
      }
    }
  }

  .search-container {
    padding: 0px 24px 112px;
  }
  .search-view {
    padding: 32px 24px 0px;
  }
}
.mobile-sort {
  position: relative;
  width: 100%;
  padding-top: 34px;
  padding-bottom: 20px;
  &:before {
    content: "";
    position: absolute;
    width: 80px;
    height: 8px;
    top: 5px;
    background: #000;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    background: #9ea7b966;
  }
  &__title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 24px;
  }
  &__item {
    position: relative;
    padding: 11px 25px;
    width: 100%;
    font-size: 16px;
    border-top: 1px solid #e9ebf8;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 24px;
    //   right: 24px;
    //   height: 1px;
    //   background: #9ea7b966;
    // }
  }
  &__item.selected {
    padding: 11px 25px;
    width: 100%;
    font-size: 16px;
    background: #e9ebf8;
  }
}
</style>
