<template>
  <div>
    <!-- <q-select
      v-model="selectedLocale"
      class="language-button font--b5-2 text-black-monochrome"
      option-value="code"
      outlined
      option-label="name"
      :options="options"
      @update:model-value="switchLanguage"
    >
      <template #option="{ opt }">
        <suspense>
          <base-icon :name="`flags/${opt.logo}`" size="1.25rem" filled />
        </suspense>
      </template>
      <template #selected-item="{ opt }">
        <suspense>
          <base-icon :name="`flags/${opt.logo}`" size="1.25rem" filled />
        </suspense>
      </template>
      <template #default>
        <suspense> </suspense>
      </template>
    </q-select> -->
    <button class="language-button">
      <span class="font--b5">
        {{ BASE_CURRENCY.code }}
      </span>
      <span>
        <suspense>
          <base-icon :name="`flags/${currentlang}`" size="20px" filled />
        </suspense>
      </span>
      <span>
        <suspense>
          <base-icon name="base/measurement" size="20px" filled />
        </suspense>
      </span>
      <q-menu
        class="language-button__menu"
        anchor="bottom middle"
        self="top middle"
        :offset="[0, 5]"
      >
        <span class="menu__title">
          <p class="font--b3-3">Language</p>
        </span>
        <span
          class="menu__item"
          v-for="item in availableLocalesData"
          @click="switchLanguage(item)"
        >
          <span class="menu__item-flag">
            <suspense>
              <base-icon :name="`flags/${item.logo}`" size="16px" filled />
            </suspense>
            <p class="font--b3-3">{{ item.name }}</p>
          </span>
          <suspense>
            <base-icon
              v-if="item.code === locale"
              name="base/Done"
              size="20px"
              color="var(--positive-secondary)"
              filled
            />
          </suspense>
        </span>
        <span class="menu__title" style="margin-top: 10px;">
          <p class="font--b3-3">Currency</p>
        </span>
        <span
          class="menu__item"
          v-for="item in selectedCurrencies"
          @click="setBaseCurrency(item.code)"
        >
          <span class="menu__item-currency">
            <p class="font--b5">{{ item.code }} - {{ item.symbol }}</p>
            <p class="font--b6 text-black-monochrome-60">
              {{ item.fullName }}
            </p>
          </span>
          <suspense>
            <base-icon
              v-if="BASE_CURRENCY.code === item.code"
              name="base/Done"
              size="20px"
              color="var(--positive-secondary)"
              filled
            />
          </suspense>
        </span>
        <span class="menu__title" style="margin-top: 10px;">
          <p class="font--b3-3">Units of measurement</p>
        </span>
        <span
          class="menu__item"
          v-for="item in measureOpts"
          @click="setBaseAreaUnit(item.code)"
        >
          <span class="menu__item-currency">
            <p class="font--b5">{{ item.title }}</p>
          </span>
          <suspense>
            <base-icon
              v-if="BASE_AREA_UNIT.code === item.code"
              name="base/Done"
              size="20px"
              color="var(--positive-secondary)"
              filled
            />
          </suspense>
        </span>
      </q-menu>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { Currency } from "~/types/currencies";
import {
  BASE_CURRENCY,
  CURRENCIES,
  setBaseCurrency,
} from "~/composables/CURRENCIES";
import { BASE_AREA_UNIT, setBaseAreaUnit } from "~/composables/AREA_UNITS";
import { getLocalStorage } from "~/services/LocalStorage/localStorage";

interface NamedCurrency extends Currency {
  fullName: string;
}

const { locale, locales, setLocale } = useI18n();
const nuxtApp = useNuxtApp();

const availableLocalesData = [
  { code: "en", name: "English", language: "en-US", logo: "united_states" },
  { code: "de", name: "Deutsch", language: "de-DE", logo: "germany" },
  // { code: "ru", name: "Русский", language: "ru-Ru", logo: "russia" },
];

const currentlang = computed(() => {
  if (locale.value === "de") {
    return "germany";
  } else if (locale.value === "ru") {
    return "russia";
  } else {
    return "united_states";
  }
});

const $q = useQuasar();

const selectedLocale = ref();

const switchLanguage = async (newLocale) => {
  if (newLocale && newLocale.code !== locale.value) {
    $q.loading.show();
    try {
      selectedLocale.value = newLocale;
      await setLocale(newLocale.code);
      web3OnboardManager.setLocale(newLocale.code)
    } finally {
      $q.loading.hide();
    }
  }
};

const getCurrencyFullName = (code: string) => {
  switch (code) {
    case "USD":
      return "United States Dollar";
    case "EUR":
      return "Euro";
    case "IDR":
      return "Indonesian Rupiah";
  }
};

const currencyOpts = ["USD", "EUR", "IDR"];

const selectedCurrencies = computed(() => {
  return currencyOpts
    .map((key) => {
      return {
        ...CURRENCIES[key],
        fullName: getCurrencyFullName(key),
      };
    })
    .filter((currency): currency is NamedCurrency => Boolean(currency));
});

const measureOpts = [
  {
    title: "Meters",
    code: "m2",
  },
  {
    title: "Square feet",
    code: "ft2",
  },
];

const userUnit = getLocalStorage("user_area_unit");

nuxtApp.hook("page:finish", () => {
  selectedLocale.value =
    availableLocalesData.find((loc) => loc.code === locale.value) ||
    availableLocalesData[0];
  if (userUnit) {
    setBaseAreaUnit(userUnit);
  }
});
</script>

<style lang="scss">
.language-button {
  @include drop-button-styles;
  position: relative;
  border-radius: 1.25rem;
  border: 1px solid transparent;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  &__menu {
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
    background: var(--Monochrome-White_contrast, #f3f7fe);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.06);
    min-width: 215px;
    height: fit-content;
    max-height: fit-content;
    .menu {
      &__title {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid var(--Color-Main_Violet, #5a2fcf);
      }
      &__item {
        width: 100%;
        display: flex;
        padding: 4px 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        background-color: transparent;
        transition: background-color 0.3s ease;
        will-change: background-color;
        cursor: pointer;
        &-flag {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
        }
        &-currency {
          display: flex;
          flex-direction: column;
        }
        &:hover {
          background-color: var(--Monochrome-White, #e9ebf8);
        }
      }
    }
  }
  // .q-field__control {
  //   padding: 0;
  //   // .q-field__append {
  //   //   display: none;
  //   // }
  //   .q-field__native {
  //     * {
  //       font-size: 14px !important;
  //     }
  //   }
  //   &::before {
  //     border: none !important;
  //   }
  //   &::after {
  //     border: none !important;
  //   }
  // }
  // :deep(.q-field__inner) {
  //   * {
  //     border: none !important;
  //   }
  // }
}
</style>
