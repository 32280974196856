<template>
  <div v-if="props.itemsAmount" class="base-paginator">
    <button
      class="font--b5 text-black-monochrome"
      :disabled="currentPage === 1"
      @click="prevPage"
    >
      {{ $t("general.prev") }}
    </button>
    <div class="base-paginator-pages">
      <button
        v-for="page in visiblePages"
        :key="page"
        class="page-butt font--b5-3 text-black-monochrome"
        :class="{ 'page-butt-active': currentPage === page }"
        @click="changePage(page)"
      >
        {{ page }}
      </button>

      <button
        v-if="shouldShowEllipsis"
        disabled
        class="page-butt font--b5-3 text-black-monochrome"
      >
        ...
      </button>
      <button
        v-if="pagesAmount > 6 && currentPage < pagesAmount - 4"
        class="page-butt font--b5-3 text-black-monochrome"
        :class="{ 'page-butt-active': currentPage === pagesAmount }"
        @click="changePage(pagesAmount)"
      >
        {{ pagesAmount }}
      </button>
    </div>

    <button
      class="font--b5 text-black-monochrome"
      :disabled="currentPage === pagesAmount"
      @click="nextPage"
    >
      {{ $t("general.next") }}
    </button>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  limit: number;
  offset: number;
  itemsAmount: number;
}

interface IEmits {
  (e: "change", value: number): void;
}

const props = defineProps<IProps>();
const emit = defineEmits<IEmits>();

const currentPage = ref(1);

const pagesAmount = computed(() => Math.ceil(props.itemsAmount / props.limit));
const currentOffset = computed(() =>
  Math.ceil(props.limit * (currentPage.value - 1))
);

const visiblePages = computed(() => {
  const totalPages = pagesAmount.value;
  if (totalPages <= 6) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  if (currentPage.value <= 4) {
    return [1, 2, 3, 4, 5];
  }

  if (currentPage.value > totalPages - 4) {
    return [
      totalPages - 4,
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ];
  }

  return [
    currentPage.value - 2,
    currentPage.value - 1,
    currentPage.value,
    currentPage.value + 1,
    currentPage.value + 2,
  ];
});

const shouldShowEllipsis = computed(() => {
  return currentPage.value < pagesAmount.value - 4;
});

const changePage = (page: number) => {

  if (currentPage.value === page) return;
  currentPage.value = page;
  console.log(' currentPage.value : ',  currentPage.value );
  
  const newOffset = currentPage.value === 1 ? 0 : currentOffset.value;
  console.log('newOffset: ', newOffset);
  
  emit("change", newOffset);
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
    emit("change", currentOffset.value);
  }
};

const nextPage = () => {
  if (currentPage.value < pagesAmount.value) {
    currentPage.value += 1;
    emit("change", currentOffset.value);
  }
};

onMounted(() => {
  currentPage.value = props.offset === 0 ? 1 : Math.ceil(props.offset / props.limit);
});
</script>

<style scoped lang="scss">
.base-paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 40px;
  button {
    @include drop-button-styles;
  }
  &-pages {
    display: flex;
    align-items: center;
    gap: 8px;
    .page-butt {
      display: flex;
      width: 28px;
      height: 24px;
      padding: 6px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      border: 1px solid var(--gray-monochrome-40);
      background: var(--white-monochrome);
      &-active {
        border-color: transparent;
        background: var(--black-monochrome);
        color: var(--white-monochrome);
      }
    }
  }
}
</style>
