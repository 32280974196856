import Web3 from "web3";
import Onboard from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import transactionPreviewModule from "@web3-onboard/transaction-preview";
import icon from "~/public/favicon.ico";
import logo from "~/assets/icons/logoDark.svg";
import { Notification } from "~/services/notifications/toast";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";

export interface WalletInfo {
  address: string;
  balance: string;
  chainId: string;
  provider: Web3 | null;
}

const notifyError = new Notification({
  type: "system",
});

const injected = injectedModule();
const transactionPreview = transactionPreviewModule({
  requireTransactionApproval: true,
});

const chains = [
  {
    id: "0x1", // Ethereum Mainnet
    token: "ETH",
    label: "Ethereum Mainnet",
    rpcUrl: "https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID",
  },
  {
    id: "0x89", // Polygon Mainnet
    token: "MATIC",
    label: "Polygon Mainnet",
    rpcUrl: "https://polygon-rpc.com/",
  },
  {
    id: "0x38", // Binance Smart Chain
    token: "BNB",
    label: "Binance Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
  {
    id: "0xa86a", // Avalanche C-Chain
    token: "AVAX",
    label: "Avalanche C-Chain",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  },
  {
    id: "0xa4b1", // Arbitrum One
    token: "ARB",
    label: "Arbitrum One",
    rpcUrl: "https://arb1.arbitrum.io/rpc",
  },
  {
    id: "0xa", // Optimism
    token: "OP",
    label: "Optimism",
    rpcUrl: "https://mainnet.optimism.io/",
  },
  {
    id: "0xfa", // Fantom Opera
    token: "FTM",
    label: "Fantom Opera",
    rpcUrl: "https://rpc.ftm.tools/",
  },
  {
    id: "0x64", // Gnosis Chain (xDAI)
    token: "xDAI",
    label: "Gnosis Chain",
    rpcUrl: "https://rpc.gnosischain.com/",
  },
  {
    id: "0x505", // Moonriver
    token: "MOVR",
    label: "Moonriver",
    rpcUrl: "https://rpc.api.moonriver.moonbeam.network/",
  },
  {
    id: "0x19", // Cronos
    token: "CRO",
    label: "Cronos",
    rpcUrl: "https://evm.cronos.org/",
  },
];

const onboard = Onboard({
  wallets: [injected],
  chains,
  appMetadata: {
    name: "RWA Estate",
    logo: logo,
    description: "RWA Estate | Explore Tokenized Real Estate",
    // recommendedInjectedWallets:[
    //   {
    //     name:'vaultX',
    //     url:'https://www.vaultx.net/'
    //   }
    // ]
  },
  transactionPreview: transactionPreview,
  connect: {
    autoConnectAllPreviousWallet: true,
    removeIDontHaveAWalletInfoLink:true
  },
  theme: {
    "--w3o-font-family": "DM Sans",
    "--w3o-background-color": "#f3f7fe",
  },
  disableFontDownload: true,
  i18n: {
    en: {
      connect: {
        selectingWallet: {
          sidebar: {
            heading: "",
            subheading: "Connect Your Web3 Wallet",
            paragraph:
              "To access and fully use all features of the RWA Estate project, please connect your Web3 wallet. Once connected, you’ll be able to explore, invest, and manage your real-world asset holdings seamlessly.",
          },
        },
      },
    },
    de: {
      connect: {
        selectingWallet: {
          sidebar: {
            heading: "",
            subheading: "Verbinden Sie Ihr Web3-Wallet",
            paragraph:
              "Um auf alle Funktionen des RWA Estate-Projekts zuzugreifen und diese voll zu nutzen, verbinden Sie bitte Ihr Web3-Wallet. Sobald die Verbindung hergestellt ist, können Sie Ihre realen Vermögensbestände nahtlos erkunden, investieren und verwalten.",
          },
        },
      },
    },
  },
});
onboard.state.actions.updateAppMetadata({
  icon: `<svg width="100%" height="100%" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><img src="${icon}" alt="" style="width: 40px;height: 40px">
  </svg>`,
});

class Web3OnboardManager {
  wallet: Ref<WalletInfo | null> = ref(null);
  isLoading: Ref<boolean> = ref(false);
  error: Ref<string | null> = ref(null);
  async connectWallet(): Promise<WalletInfo[] | null> {
    this.isLoading.value = true;
    try {
      const wallets = await onboard.connectWallet();
      if (wallets.length === 0) {
        notifyError.createNewToast("No wallet connected. Please try later.");
        return null;
      }

      this.observeWalletState();
      const connectedWallets = wallets.map((wallet) => wallet.label);
      writePersistentLocalStorage(
        "connectedWallets",
        JSON.stringify(connectedWallets.map((w) => w))
      );
      this.isLoading.value = false;
      return connectedWallets;
    } catch (err) {
      console.error(err);
      notifyError.createNewToast("Failed to connect wallet. Please try again.");
      this.isLoading.value = false;
      return null;
    }
  }

  async disconnectWallet() {
    try {
      if (!this.wallet.value) {
        notifyError.createNewToast("Some error occured. Please try later");
        throw new Error("Some error occured. Please try later");
      }

      await onboard.disconnectWallet({ label: this.wallet.value.address });
      this.wallet.value = null;
    } catch (err: any) {
      this.error.value = err.message || "Some error occured. Please try later";
    }
  }

  async addTokenToPrimaryWallet({
    address,
    symbol,
    decimals,
    image = "",
  }: {
    address: string;
    symbol: string;
    decimals: number;
    image?: string;
  }): Promise<boolean> {
    try {
      let primaryWallet = this.getPrimaryWallet();
      if (!primaryWallet) {
        notifyError.createNewToast("Please, connect wallet first.");
        await this.connectWallet();
        primaryWallet = this.getPrimaryWallet();
      }

      const provider = primaryWallet?.provider as any;
      if (!provider.request) {
        notifyError.createNewToast(
          "Adding a token is not supported by this wallet."
        );
        throw new Error("Adding a token is not supported by this wallet.");
      }

      await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals: decimals || 1,
            image,
          },
        },
      });

      console.log(
        `The ${symbol} token has been successfully added to the main wallet.`
      );
      return true;
    } catch (err: any) {
      console.error(err.message);
      this.error.value = err.message;
      notifyError.createNewToast(err.message);
      return false;
    }
  }

  async addTokenToWalletByName({
    walletLabel,
    address,
    symbol,
    decimals,
    image = "",
  }: {
    walletLabel: string;
    address: string;
    symbol: string;
    decimals: number;
    image?: string;
  }): Promise<boolean> {
    try {
      const wallet = this.getWalletByLabel(walletLabel);
      if (!wallet) {
        notifyError.createNewToast("Wallet not found.");
        throw new Error("Wallet not found.");
      }

      const provider = wallet.provider as any;
      if (!provider.request) {
        notifyError.createNewToast(
          "Adding a token is not supported by this wallet."
        );
        throw new Error("Adding a token is not supported by this wallet.");
      }

      await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals: decimals || 1,
            image,
          },
        },
      });

      console.log(
        `The ${symbol} token has been successfully added to the ${walletLabel} wallet.`
      );
      return true;
    } catch (err: any) {
      console.error(err.message);
      this.error.value = err.message;
      return false;
    }
  }

  // Получение кошелька по метке
  getWalletByLabel(label: string): WalletInfo | null {
    const wallet = onboard.state.get().wallets.find((w) => w.label === label);
    return wallet || null;
  }

  getPrimaryWallet(): WalletInfo | null {
    const [primaryWallet] = onboard.state.get().wallets;
    if (primaryWallet) {
      return primaryWallet;
    }
    return null;
  }

  async restoreWallet(): Promise<void> {
    try {
      const connectedWallets = JSON.parse(
        getLocalStorage("connectedWallets") || "[]"
      );
      if (connectedWallets.length === 0) {
        return;
      }
      for (const walletLabel of connectedWallets) {
        await onboard.connectWallet({
          autoSelect: {
            label: walletLabel,
            disableModals: true,
          },
        });
      }

      const wallets = onboard.state.get().wallets;
      if (wallets.length > 0) {
        this.wallet.value = {
          address: wallets[0].accounts[0].address,
          chainId: wallets[0].chains[0].id,
          provider: new Web3(wallets[0].provider),
        };
      }
      this.observeWalletState();
    } catch (err: any) {
      console.error(err.message);
      this.error.value = err.message;
    }
  }

  observeWalletState() {
    const wallets = onboard.state.select("wallets");
    const { unsubscribe } = wallets.subscribe((update) => {
      const connectedWallets = update.map((wallet) => wallet.label);
      writePersistentLocalStorage(
        "connectedWallets",
        JSON.stringify(connectedWallets)
      );
    });
    return unsubscribe;
  }

  setLocale(locale: string) {
    onboard.state.actions.setLocale(locale);
  }
}

export const web3OnboardManager = new Web3OnboardManager();
